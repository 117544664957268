import React, { useState } from "react";
import AppBar from "@material-ui/core/AppBar";
import MenuIcon from "@material-ui/icons/Menu";
import Toolbar from "@material-ui/core/Toolbar";
import CloseIcon from "@material-ui/icons/Close";
import { useTheme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import MobileMenu from "./MobileMenu";
import Logo from "../../../assets/Logo/Logo.png";
import "../../../App.css";
import { Drawer } from "antd";
import "antd/dist/antd.css";

const useStyles = makeStyles(() => ({
  root: {
    background: "white",
    boxShadow: "none",
    width: "100%",
    marginTop: "-1px",
    zIndex: 11,
    padding: "10px 0px",
    flexDirection: "row",
  },
  toolRoot: {
    width: "100%",
  },
  MenuIcon: {
    color: "black",
    marginLeft: "20px",
  },
  IpadRoot: {
    display: "flex",
    justifyContent: "left",
    width: "100%",
    paddingTop: "5px",
  },
  MobileRoot: {
    display: "flex",
    width: "100%",
  },
  DrawerRoot: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

const MobileHeader = () => {
  const classes = useStyles();
  const theme = useTheme();
  const Ipad = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const [visible, setVisible] = useState(false);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const GotoHome = () => {
    window.location.href = "/";
  };

  return (
    <>
      <AppBar position="fixed" className={classes.root}>
        <Toolbar className={classes.toolRoot}>
          {Ipad ? (
            <div className={classes.IpadRoot}>
              <img src={Logo} alt="img" width={100} onClick={GotoHome} />
            </div>
          ) : (
            <div className={classes.MobileRoot}>
              <img src={Logo} alt="img" width={100} onClick={GotoHome} />
            </div>
          )}
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={showDrawer}
          >
            <MenuIcon className={classes.MenuIcon} />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        title={
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <img src={Logo} alt="img" width={150} />
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </div>
        }
        closable={false}
        placement="right"
        onClose={onClose}
        visible={visible}
      >
        <div style={{ padding: "0px" }}>
          <MobileMenu />
        </div>
      </Drawer>
    </>
  );
};

export default MobileHeader;
