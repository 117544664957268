import React from "react";
import Header from "../../component/Header";
import Footer from "../../component/Footer";
import CopyRight from "../../component/CopyRight";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import { makeStyles } from "@material-ui/core/styles";
import { motion } from "framer-motion";
import { Circle } from "../../Animation/Circle";
import { TitleText, FadeIn } from "../../Animation/TitleText";

import Right from "../../assets/Images/Right.png";
import Left_2 from "../../assets/Images/Left_2.png";
import House from "../../assets/Images/Measurement/House.png";
import Smart from "../../assets/Images/Measurement/Smart.png";
import Apartment from "../../assets/Images/Measurement/Apartment.png";
import Industrial from "../../assets/Images/Measurement/Industrial.png";
import Combination from "../../assets/Images/Measurement/Combination.png";

import P120 from "../../pdf/Measurement/House/120.pdf";
import P420 from "../../pdf/Measurement/House/420.pdf";
import P620 from "../../pdf/Measurement/House/620.pdf";
import ResidiaJet from "../../pdf/Measurement/Apartment/ResidiaJet.pdf";
import ResidiaJet_C from "../../pdf/Measurement/Apartment/ResidiaJet_C.pdf";
import MeiStream from "../../pdf/Measurement/Industrial/MeiStream.pdf";
import MeiStreamFs from "../../pdf/Measurement/Industrial/MeiStreamFs.pdf";
import MeiStreamPlus from "../../pdf/Measurement/Industrial/MeiStreamPlus.pdf";
import iPERL from "../../pdf/Measurement/Smart/iPERL.pdf";
import P640 from "../../pdf/Measurement/Smart/640.pdf";
import P640C from "../../pdf/Measurement/Smart/640C.pdf";
import MeiStreamRF from "../../pdf/Measurement/Smart/MeiStreamRF.pdf";
import MeiTwinRF from "../../pdf/Measurement/Smart/MeiTwinRF.pdf";
import MeiTwin from "../../pdf/Measurement/Combination/MeiTwin.pdf";
import MPV_MS from "../../pdf/Measurement/Combination/MPV-MS.pdf";

import HeroImage from "./HeroImage/HeroImage";

const useStyles = makeStyles((theme) => ({
  Hero_text_div: {
    position: "absolute",
    zIndex: 1,
    width: "100%",
    height: "80%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    textAlign: "center",
  },
  Hero_image: {
    width: "100%",
    height: "700px",
    [theme.breakpoints.down("xs")]: {
      objectFit: "cover",
      height: "500px",
    },
  },
  Div_image: {
    width: "400px",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      marginTop: "30px",
    },
  },
  Div_1: {
    width: "100%",
    height: "700px",
    paddingTop: "120px",
    position: "relative",
    [theme.breakpoints.down("xs")]: {
      paddingTop: "50px",
      height: "100%",
    },
  },
  Div_2: {
    width: "100%",
    height: "600px",
    position: "relative",
    [theme.breakpoints.down("xs")]: {
      height: "100%",
      paddingBottom: "10px",
    },
  },
  Title: {
    fontSize: "35px",
    lineHeight: 1.2,
    fontWeight: 600,
    color: "rgba(0, 62, 106, 1)",
    marginTop: "10px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "25px",
      marginTop: "30px",
    },
  },
  Data_div: {
    fontWeight: 500,
    fontSize: "20px",
    marginTop: "30px",
    // paddingTop: "50px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
      marginTop: "20px",
    },
  },
  Drop: {
    background: "green",
    width: "25px",
    height: "25px",
    borderRadius: "50% 0 50% 50%",
    transform: "rotate(-45deg)",
    marginRight: "10px",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      marginTop: "5px",
    },
  },
  Drop2: {
    background: "green",
    width: "25px",
    height: "25px",
    borderRadius: "50% 0 50% 50%",
    transform: "rotate(-45deg)",
    marginRight: "10px",
    marginLeft: "20px",
    [theme.breakpoints.down("xs")]: {
      marginLeft: "30px",
    },
  },
  Drop_Main_div: { display: "flex", flexDirection: "row" },
  Drop_1_div: {
    width: "240px",
    display: "flex",
    alignItems: "center",
    marginTop: "10px",
    border: "2px solid rgba(0, 62, 106, 1)",
    borderRadius: "5px",
    padding: "10px",
    cursor: "pointer",
    [theme.breakpoints.down("xs")]: {
      marginTop: "10px",
      width: "165px",
    },
  },
  Drop_div: {
    width: "240px",
    display: "flex",
    alignItems: "center",
    marginTop: "30px",
    border: "2px solid rgba(0, 62, 106, 1)",
    borderRadius: "5px",
    padding: "10px",
    cursor: "pointer",
    [theme.breakpoints.down("xs")]: {
      marginTop: "40px",
      width: "165px",
    },
  },
  Drop_2_div: {
    marginLeft: "50px",
    [theme.breakpoints.down("xs")]: {
      marginLeft: "20px",
    },
  },
  Edd_div: {
    width: "265px",
    display: "flex",
    alignItems: "center",
    marginTop: "10px",
    border: "2px solid rgba(0, 62, 106, 1)",
    borderRadius: "5px",
    padding: "10px",
    cursor: "pointer",
    [theme.breakpoints.down("xs")]: {
      marginTop: "10px",
      width: "165px",
    },
  },
  Hero_Title: {
    fontSize: "65px",
    fontWeight: 600,
    lineHeight: 1,
    color: "white",
    [theme.breakpoints.down("xs")]: {
      fontSize: "35px",
    },
  },
}));
const Measurement = () => {
  const classes = useStyles();
  return (
    <div>
      <Header />
      <motion.div
        variants={TitleText}
        initial="initial"
        animate="animate"
        className={classes.Hero_text_div}
      >
        <motion.span variants={FadeIn("down")} className={classes.Hero_Title}>
          Measurement Metrology
        </motion.span>
      </motion.div>
      <HeroImage />
      <div
        style={{
          background: "linear-gradient(180deg, #FFFFFF 50%, #D7EEFF 103.28%)",
          height: "100%",
        }}
      >
        <div className={classes.Div_1}>
          <div style={{ position: "absolute", left: 0, bottom: 50 }}>
            <motion.img
              variants={Circle}
              initial="initial"
              animate="animate"
              src={Right}
              alt="img"
            />
          </div>
          <Container maxWidth="lg">
            <Grid container>
              <Grid item lg={6} md={6} xs={12} order={{ xs: 3, sm: 2 }}>
                <div>
                  <div>
                    <h1 className={classes.Title}>
                      <span style={{ color: "#00A859" }}>Smart&nbsp;</span>
                      Water Meters
                    </h1>
                  </div>
                  <div>
                    <span
                      style={{
                        fontSize: "16px",
                        fontWeight: 600,
                      }}
                    >
                      iPERL
                    </span>
                    <p
                      style={{
                        fontSize: "15px",
                        fontWeight: 400,
                        lineHeight: "normal",
                      }}
                    >
                      Unlike other solid state meters, iPERL uses remanent
                      magnetic field technology which provides a linear
                      measurement range even down to very low flow rates.
                    </p>
                    <p>
                      Sensus iPERL offers unrivalled, sustained R800 measurement
                      accuracy for all sizes from DN15 to DN40 over its expected
                      15 year operational life when used for clean potable water
                    </p>
                  </div>
                  <div className={classes.Data_div} style={{}}>
                    <div className={classes.Drop_Main_div}>
                      <div>
                        <a href={iPERL} target="_blank" rel="noreferrer">
                          <div className={classes.Drop_1_div}>
                            <div className={classes.Drop}></div>
                            iPERL
                          </div>
                        </a>
                        <a href={P640} target="_blank" rel="noreferrer">
                          <div className={classes.Drop_div}>
                            <div className={classes.Drop}></div>
                            640
                          </div>
                        </a>
                        <a href={MeiStreamRF} target="_blank" rel="noreferrer">
                          <div className={classes.Drop_div}>
                            <div className={classes.Drop}></div>
                            MeiStreamRF
                          </div>
                        </a>
                      </div>
                      <div className={classes.Drop_2_div}>
                        <a href={P640C} target="_blank" rel="noreferrer">
                          <div className={classes.Drop_1_div}>
                            <div className={classes.Drop}></div>
                            640C
                          </div>
                        </a>
                        <a href={MeiTwinRF} target="_blank" rel="noreferrer">
                          <div className={classes.Drop_div}>
                            <div className={classes.Drop}></div>
                            MeiTwinRF
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item lg={6} md={6} xs={12} order={{ xs: 2, sm: 3 }}>
                <div>
                  <img src={Smart} alt="img" className={classes.Div_image} />
                </div>
              </Grid>
            </Grid>
          </Container>
        </div>
        <div className={classes.Div_2}>
          <div style={{ position: "absolute", right: 0, bottom: 50 }}>
            <motion.img
              variants={Circle}
              initial="initial"
              animate="animate"
              src={Left_2}
              alt="img"
            />
          </div>
          <Container maxWidth="lg">
            <Grid container>
              <Grid item lg={6} md={6} xs={12}>
                <div>
                  <img src={House} alt="img" className={classes.Div_image} />
                </div>
              </Grid>
              <Grid item lg={6} md={6} xs={12}>
                <div>
                  <div>
                    <h1 className={classes.Title}>
                      <span style={{ color: "#00A859" }}>House&nbsp;</span>
                      Water Meters
                    </h1>
                  </div>
                  <div>
                    <p
                      style={{
                        fontSize: "15px",
                        fontWeight: 400,
                        lineHeight: "normal",
                      }}
                    >
                      As with all meters in the Sensus portfolio, the wet dial
                      420 and protected 420PC multijet meter benefits from our
                      long experience in the manufacture of highperformance
                      meters. The 420/420PC reliability, resistance to bad water
                      quality and quite operation will satisfy both end users
                      and network managers.
                    </p>
                  </div>
                  <div className={classes.Data_div}>
                    <div className={classes.Drop_Main_div}>
                      <div>
                        <a href={P120} target="_blank" rel="noreferrer">
                          <div className={classes.Drop_1_div}>
                            <div className={classes.Drop}></div>
                            120
                          </div>
                        </a>
                        <a href={P420} target="_blank" rel="noreferrer">
                          <div className={classes.Drop_div}>
                            <div className={classes.Drop}></div>
                            420 and 420PC
                          </div>
                        </a>
                      </div>
                      <div className={classes.Drop_2_div}>
                        <a href={P620} target="_blank" rel="noreferrer">
                          <div className={classes.Drop_1_div}>
                            <div className={classes.Drop}></div>
                            620
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Container>
        </div>
        <div className={classes.Div_2}>
          <div style={{ position: "absolute", left: 0, bottom: 50 }}>
            <motion.img
              variants={Circle}
              initial="initial"
              animate="animate"
              src={Right}
              alt="img"
            />
          </div>
          <Container maxWidth="lg">
            <Grid container>
              <Grid item lg={6} md={6} xs={12} order={{ xs: 3, sm: 2 }}>
                <div>
                  <div>
                    <h1 className={classes.Title}>
                      <span style={{ color: "#00A859" }}>Industrial&nbsp;</span>
                      Water Meters
                    </h1>
                  </div>
                  <div
                    style={{
                      fontSize: "15px",
                      fontWeight: 400,
                      lineHeight: "normal",
                    }}
                  >
                    <span style={{ fontSize: "16px", fontWeight: 600 }}>
                      MeiStream
                    </span>
                    <p style={{ paddingTop: "10px" }}>
                      <b>-</b> is the latest bulk water meter development of
                      Sensus
                    </p>
                    <p>
                      <b>-</b> is a milestone in the development of mechanical
                      water meters
                    </p>
                    <p>
                      <b>-</b> based on a unique new rotor bearing system
                      (patent pending)
                    </p>
                    <p>
                      <b>-</b> simplifies the choice of the suitable meter for a
                      particular measuring task
                    </p>
                  </div>
                  <div className={classes.Data_div}>
                    <div className={classes.Drop_Main_div}>
                      <div>
                        <a href={MeiStream} target="_blank" rel="noreferrer">
                          <div className={classes.Drop_1_div}>
                            <div className={classes.Drop}></div>
                            MeiStream
                          </div>
                        </a>
                        <a href={MeiStreamFs} target="_blank" rel="noreferrer">
                          <div className={classes.Drop_div}>
                            <div className={classes.Drop}></div>
                            MeiStream FS
                          </div>
                        </a>
                      </div>
                      <div className={classes.Drop_2_div}>
                        <a
                          href={MeiStreamPlus}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <div className={classes.Drop_1_div}>
                            <div className={classes.Drop}></div>
                            MeiStream Plus
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item lg={6} md={6} xs={12} order={{ xs: 2, sm: 3 }}>
                <div>
                  <img
                    src={Industrial}
                    alt="img"
                    className={classes.Div_image}
                  />
                </div>
              </Grid>
            </Grid>
          </Container>
        </div>
        <div className={classes.Div_2}>
          <div style={{ position: "absolute", right: 0, bottom: 50 }}>
            <motion.img
              variants={Circle}
              initial="initial"
              animate="animate"
              src={Left_2}
              alt="img"
            />
          </div>
          <Container maxWidth="lg">
            <Grid container>
              <Grid item lg={6} md={6} xs={12}>
                <div>
                  <img
                    src={Combination}
                    alt="img"
                    className={classes.Div_image}
                  />
                </div>
              </Grid>
              <Grid item lg={6} md={6} xs={12}>
                <div>
                  <div>
                    <h1 className={classes.Title}>
                      <span style={{ color: "#00A859" }}>
                        Combination&nbsp;
                      </span>
                      Water Meters
                    </h1>
                  </div>
                  <div
                    style={{
                      fontSize: "15px",
                      fontWeight: 400,
                      lineHeight: "normal",
                    }}
                  >
                    <span style={{ fontSize: "16px", fontWeight: 600 }}>
                      MeiTwin
                    </span>
                    <p>
                      The main meter and the by-pass meter are arranged one
                      behind the other in the direction of flow.
                    </p>
                    <p>
                      A multirange metrological unit allows an easy economical
                      replacement after the validity period of the calibration
                      has expired. There is possibility to replace it in 15
                      minutes!
                    </p>
                  </div>
                  <div className={classes.Data_div}>
                    <div className={classes.Drop_Main_div}>
                      <div style={{ paddingBottom: "30px" }}>
                        <a href={MeiTwin} target="_blank" rel="noreferrer">
                          <div className={classes.Drop_1_div}>
                            <div className={classes.Drop}></div>
                            MeiTwin
                          </div>
                        </a>
                      </div>
                      <div className={classes.Drop_2_div}>
                        <a href={MPV_MS} target="_blank" rel="noreferrer">
                          <div className={classes.Drop_1_div}>
                            <div className={classes.Drop}></div>
                            MPV-MS
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Container>
        </div>
        <div className={classes.Div_2}>
          <div style={{ position: "absolute", left: 0, bottom: 50 }}>
            <motion.img
              variants={Circle}
              initial="initial"
              animate="animate"
              src={Right}
              alt="img"
            />
          </div>
          <Container maxWidth="lg">
            <Grid container>
              <Grid item lg={6} md={6} xs={12} order={{ xs: 3, sm: 2 }}>
                <div>
                  <div>
                    <h1 className={classes.Title}>
                      <span style={{ color: "#00A859" }}>Apartment&nbsp;</span>
                      Water Meters
                    </h1>
                  </div>
                  <div
                    style={{
                      fontSize: "15px",
                      fontWeight: 400,
                      lineHeight: "normal",
                    }}
                  >
                    <span style={{ fontSize: "16px", fontWeight: 600 }}>
                      Residia Jet
                    </span>
                    <p>
                      DN 15 - 20 Single jet dry dial meter with magnetic
                      coupling Magnetic transmission Suitable up to 50 °C as a
                      cold water meter Suitable from 30 °C to 90 °C as a warm
                      water meter Insensitive to upstream disruptive elements
                      355° rotating register High resistance to water impurities
                      AMR pre-equipped for pulse or radio interface
                    </p>
                  </div>
                  <div className={classes.Data_div}>
                    <div className={classes.Drop_Main_div}>
                      <div>
                        <a href={ResidiaJet} target="_blank" rel="noreferrer">
                          <div className={classes.Drop_1_div}>
                            <div className={classes.Drop}></div>
                            ResidiaJet
                          </div>
                        </a>
                      </div>
                      <div className={classes.Drop_2_div}>
                        <a href={ResidiaJet_C} target="_blank" rel="noreferrer">
                          <div className={classes.Drop_1_div}>
                            <div className={classes.Drop}></div>
                            ResidiaJet - C<br />
                            Qn 1,5
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item lg={6} md={6} xs={12} order={{ xs: 2, sm: 3 }}>
                <div>
                  <img
                    src={Apartment}
                    alt="img"
                    className={classes.Div_image}
                  />
                </div>
              </Grid>
            </Grid>
          </Container>
        </div>
      </div>
      <div>
        <Footer />
        <CopyRight />
      </div>
    </div>
  );
};

export default Measurement;
