import React from "react";
import { useTheme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Hero_1 from "../../assets/Video/Hero_1.mp4";
import Hero_2 from "../../assets/Video/Hero_2.mp4";

const useStyles = makeStyles((theme) => ({
  Main_image: {
    width: "100%",
    height: "700px",
  },
  Mobile_image: { width: "100%", height: "500px", objectFit: "cover" },
}));

const CarouselImage = () => {
  const classes = useStyles();
  const theme = useTheme();
  const Mobile = useMediaQuery(theme.breakpoints.down("xs"));

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplaySpeed: 18000,
    autoplay: true,
  };

  return (
    <>
      <div style={{ marginBottom: "-76px" }}>
        {Mobile ? (
          <>
            <div
              style={{ width: "100%", overflowX: "hidden", height: "400px" }}
            >
              <Slider {...settings}>
                <div>
                  <video
                    autoPlay
                    loop
                    muted
                    style={{
                      width: "100%",
                      height: "350px",
                      objectFit: "cover",
                    }}
                  >
                    <source src={Hero_1} type="video/mp4" />
                  </video>
                </div>
                <div>
                  <video
                    autoPlay
                    loop
                    muted
                    style={{
                      width: "100%",
                      height: "350px",
                      objectFit: "cover",
                    }}
                  >
                    <source src={Hero_2} type="video/mp4" />
                  </video>
                </div>
              </Slider>
            </div>
          </>
        ) : (
          <>
            <Slider {...settings}>
              <div style={{ width: "100%", marginLeft: "5rem" }}>
                <video autoPlay loop muted style={{ width: "100%" }}>
                  <source src={Hero_1} type="video/mp4" />
                </video>
              </div>
              <div>
                <video autoPlay loop muted style={{ width: "100%" }}>
                  <source src={Hero_2} type="video/mp4" />
                </video>
              </div>
            </Slider>
          </>
        )}
      </div>
    </>
  );
};

export default CarouselImage;
