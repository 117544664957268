import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { motion } from "framer-motion";
import { Circle } from "../../Animation/Circle";

import Left_2 from "../../assets/Images/Left_2.png";

const useStyles = makeStyles((theme) => ({
  Main_container: {
    width: "100%",
    height: "100%",
    paddingTop: "100px",
    paddingBottom: "50px",
    [theme.breakpoints.down("xs")]: {
      paddingTop: "50px",
    },
  },
  Title_div: {
    display: "flex",
    justifyContent: "center",
  },
  Title_text: {
    fontSize: "35px",
    lineHeight: 1.2,
    fontWeight: 600,
    color: "rgba(0, 62, 106, 1)",
    [theme.breakpoints.down("xs")]: {
      fontSize: "25px",
      paddingRight: "0px",
    },
  },
  Div_1: {
    marginTop: "50px",
    [theme.breakpoints.down("xs")]: {
      marginTop: "20px",
    },
  },
  Div_2: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "50px",
    [theme.breakpoints.down("xs")]: {
      marginTop: "0px",
    },
  },
  Grid_2: {
    display: "flex",
    justifyContent: "flex-end",
  },

  Drop_icon: {
    background: "green",
    width: "25px",
    height: "25px",
    borderRadius: "50% 0 50% 50%",
    transform: "rotate(-45deg)",
    marginRight: "10px",
  },
  Block: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "350px",
    height: "60px",
    background: "white",
    borderRadius: "50px",
    color: "rgba(0, 62, 106, 1)",
    fontSize: "20px",
    "&:hover": {
      backgroundColor: "rgba(0, 62, 106, 1)",
      color: "white",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "15px",
      width: "100%",
      height: "50px",
      textAlign: "left",
      display: "flex",
      justifyContent: "start",
      paddingLeft: "30px",
      marginTop: "10px",
    },
  },
  Block2: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "350px",
    height: "60px",
    background: "white",
    borderRadius: "50px",
    color: "rgba(0, 62, 106, 1)",
    fontSize: "20px",
    marginRight: "20px",
    "&:hover": {
      backgroundColor: "rgba(0, 62, 106, 1)",
      color: "white",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "15px",
      width: "100%",
      height: "50px",
      marginRight: "0px",
      textAlign: "left",
      display: "flex",
      justifyContent: "start",
      paddingLeft: "30px",
      marginTop: "10px",
    },
  },
}));

const MobileApp = () => {
  const classes = useStyles();
  return (
    <div>
      <div style={{ position: "absolute", right: 0 }}>
        <motion.img
          variants={Circle}
          initial="initial"
          animate="animate"
          src={Left_2}
          alt="img"
          style={{ width: "130px" }}
        />
      </div>
      <Container maxWidth="lg">
        <div className={classes.Main_container}>
          <div className={classes.Title_div}>
            <h1 className={classes.Title_text}>
              <sapn style={{ color: "green" }}>Individual AURA&nbsp;</sapn>
              Mobile Applications
            </h1>
          </div>
          <div className={classes.Div_1}>
            <Grid container>
              <Grid item xs={12} md={4}>
                <div className={classes.Block}>
                  <div className={classes.Drop_icon}></div>
                  <div>AURA Programming</div>
                </div>
              </Grid>
              <Grid item xs={12} md={4}>
                <div className={classes.Block}>
                  <div className={classes.Drop_icon}></div>
                  <div>AURA Diagnostics</div>
                </div>
              </Grid>
              <Grid item xs={12} md={4}>
                <div className={classes.Block}>
                  <div className={classes.Drop_icon}></div>
                  <div>AURA Collector</div>
                </div>
              </Grid>
            </Grid>
          </div>
          <div className={classes.Div_2}>
            <Grid container>
              <Grid item xs={12} md={6} className={classes.Grid_2}>
                <div className={classes.Block2}>
                  <div className={classes.Drop_icon}></div>
                  <div>AURA Mapping & Assist</div>
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className={classes.Block}>
                  <div className={classes.Drop_icon}></div>
                  <div>AURA Network</div>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default MobileApp;
