import React from "react";
import { useHistory } from "react-router-dom";
import Container from "@mui/material/Container";
import { useTheme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Logo from "../../assets/Logo/Logo.png";
import MobileHeader from "./sub-component/MobileHeader";

const useStyles = makeStyles(() => ({
  Root: {
    display: "flex",
    justifyContent: "space-between",
    paddingTop: "15px",
    width: "100%",
  },
  scroll1: {
    backgroundColor: "white",
    width: "100%",
    position: "fixed",
    zIndex: "11",
  },
  scroll2: {
    backgroundColor: "#ffffff87",
    width: "100%",
    position: "fixed",
    zIndex: "11",
  },
  Root1: {
    display: "flex",
    justifyContent: "space-between",
    paddingTop: "15px",
    width: "100%",
  },
  Header_div: {
    display: "flex",
    alignItems: "center",
    width: "70%",
    justifyContent: "space-around",
  },
  Sub_div: {
    fontWeight: "500",
    fontSize: "16px",
    color: "rgba(0, 62, 106, 1)",
    cursor: "pointer",
    "&:hover": {
      color: "black",
    },
  },
  Active_Sub_div: {
    fontWeight: "500",
    fontSize: "16px",
    color: "black",
    cursor: "pointer",
    border: "1px solid green",
    padding: "10px",
    borderRadius: "10px",
    "&:hover": {
      color: "rgba(0, 62, 106, 1) ",
    },
  },
  Sub_div2: {
    fontWeight: "500",
    fontSize: "16px",
    color: "#878787",
    cursor: "pointer",
    "&:hover": {
      color: "black ",
    },
  },
  SoftWare_div: {
    display: "flex",
    fontWeight: "500",
    fontSize: "16px",
    alignItems: "center",
    color: "#878787",
    cursor: "pointer",
  },
  Aura_button: {
    boxSizing: "border-box",
    borderRadius: "10px",
    border: "none",
    padding: "5px 23px",
    paddingBottom: "4px",
    fontSize: "20px",
    color: "white",
    fontWeight: "500",
    background: "#003256",
    // border: "2px solid #00A859",
    cursor: "pointer",
    // color: "black",
    "&:hover": {
      // background: "rgba(0, 62, 106, 1)",
    },
  },
  Aura_button2: {
    boxSizing: "border-box",
    // border: "2px solid #00A859",
    borderRadius: "10px",
    border: "none",
    padding: "5px 23px",
    paddingBottom: "4px",
    fontSize: "20px",
    color: "rgba(0, 62, 106, 1)",
    fontWeight: "500",
    background: "transparent",
    cursor: "pointer",
    "&:hover": {
      // background: "rgba(0, 62, 106, 1)",
      color: "white",
    },
  },
  Active: {
    color: "black",
    fontWeight: "500",
    fontSize: "16px",

    cursor: "pointer",
    "&:hover": {
      color: "white ",
    },
  },
}));

const Header = () => {
  const theme = useTheme();
  const Mobile = useMediaQuery(theme.breakpoints.down("xs"));
  const classes = useStyles();
  const history = useHistory();

  const GotoHome = () => {
    history.push("/");
  };

  const GotoMeasurement = () => {
    history.push("/Measurement");
  };

  const GotoCommunication = () => {
    history.push("/Communication");
  };

  const GotoAura = () => {
    history.push("/Aura");
  };

  const GotoAboutUs = () => {
    history.push("/AboutUs");
  };

  const [scroll_active, setScroll_Active] = React.useState();

  const changebg = () => {
    if (window.scrollY >= 20) {
      setScroll_Active(true);
    } else {
      setScroll_Active(false);
    }
  };
  window.addEventListener("scroll", changebg);

  return (
    <>
      {scroll_active ? (
        <>
          {Mobile ? (
            <MobileHeader />
          ) : (
            <>
              <div className={classes.scroll1}>
                <Container maxWidth="lg">
                  <div className={classes.Root}>
                    <div>
                      <img
                        src={Logo}
                        width={160}
                        alt="Logo"
                        onClick={GotoHome}
                      />
                    </div>
                    <div className={classes.Header_div}>
                      {window.location.pathname === "/" ? (
                        <div
                          className={classes.Active_Sub_div}
                          onClick={GotoHome}
                        >
                          Home
                        </div>
                      ) : (
                        <div className={classes.Sub_div} onClick={GotoHome}>
                          Home
                        </div>
                      )}
                      {window.location.pathname === "/Measurement" ? (
                        <div
                          className={classes.Active_Sub_div}
                          onClick={GotoMeasurement}
                        >
                          Measurement
                        </div>
                      ) : (
                        <div
                          className={classes.Sub_div}
                          onClick={GotoMeasurement}
                        >
                          Measurement
                        </div>
                      )}

                      {window.location.pathname === "/Communication" ? (
                        <div
                          className={classes.Active_Sub_div}
                          onClick={GotoCommunication}
                        >
                          Communication
                        </div>
                      ) : (
                        <div
                          className={classes.Sub_div}
                          onClick={GotoCommunication}
                        >
                          Communication
                        </div>
                      )}
                      {window.location.pathname === "/AboutUs" ? (
                        <div
                          className={classes.Active_Sub_div}
                          onClick={GotoAboutUs}
                        >
                          About
                        </div>
                      ) : (
                        <div className={classes.Sub_div} onClick={GotoAboutUs}>
                          About
                        </div>
                      )}
                      <div>
                        <button
                          className={classes.Aura_button}
                          onClick={GotoAura}
                        >
                          Aura Software
                        </button>
                      </div>
                    </div>
                  </div>
                </Container>
              </div>
            </>
          )}
        </>
      ) : (
        <>
          <div style={{ background: "#e7e1e1" }}>
            {Mobile ? (
              <MobileHeader />
            ) : (
              <>
                <div className={classes.scroll2}>
                  <Container maxWidth="lg">
                    <div className={classes.Root}>
                      <div>
                        <img
                          src={Logo}
                          width={160}
                          alt="Logo"
                          onClick={GotoHome}
                        />
                      </div>
                      <div className={classes.Header_div}>
                        {window.location.pathname === "/" ? (
                          <div
                            className={classes.Active_Sub_div}
                            onClick={GotoHome}
                          >
                            Home
                          </div>
                        ) : (
                          <div className={classes.Sub_div} onClick={GotoHome}>
                            Home
                          </div>
                        )}
                        {window.location.pathname === "/Measurement" ? (
                          <div
                            className={classes.Active_Sub_div}
                            onClick={GotoMeasurement}
                          >
                            Measurement
                          </div>
                        ) : (
                          <div
                            className={classes.Sub_div}
                            onClick={GotoMeasurement}
                          >
                            Measurement
                          </div>
                        )}

                        {window.location.pathname === "/Communication" ? (
                          <div
                            className={classes.Active_Sub_div}
                            onClick={GotoCommunication}
                          >
                            Communication
                          </div>
                        ) : (
                          <div
                            className={classes.Sub_div}
                            onClick={GotoCommunication}
                          >
                            Communication
                          </div>
                        )}
                        {window.location.pathname === "/AboutUs" ? (
                          <div
                            className={classes.Active_Sub_div}
                            onClick={GotoAboutUs}
                          >
                            About
                          </div>
                        ) : (
                          <div
                            className={classes.Sub_div}
                            onClick={GotoAboutUs}
                          >
                            About
                          </div>
                        )}

                        <div>
                          <button
                            className={classes.Aura_button}
                            onClick={GotoAura}
                          >
                            Aura Software
                          </button>
                        </div>
                      </div>
                    </div>
                  </Container>
                </div>
              </>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default Header;
