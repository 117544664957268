import React from "react";
import MobileApp from "./MobileApp";
import MillionDiv from "./MillionDiv";
import Header from "../../component/Header";
import Footer from "../../component/Footer";
import CopyRight from "../../component/CopyRight";
import CarouselImage from "../../component/CarouselImage";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import { makeStyles } from "@material-ui/core/styles";
import { motion } from "framer-motion";
import { Circle } from "../../Animation/Circle";
import { TitleText, FadeIn } from "../../Animation/TitleText";

import Aura1 from "../../pdf/Aura/Aura.pdf";
import Main_Aura from "../../assets/Images/Main_Aura.png";
import Right from "../../assets/Images/Right.png";

const useStyles = makeStyles((theme) => ({
  Root: {
    width: "100%",
  },
  Main_Image: {
    width: "100%",
    height: "700px",
    [theme.breakpoints.down("xs")]: {
      height: "500px",
      objectFit: "cover",
    },
  },
  Gradient_div: {
    width: "100%",
    background: "linear-gradient(180deg, #FFFFFF 50.04%, #D7EEFF 103.28%)",
    [theme.breakpoints.down("xs")]: {
      marginTop: "100px",
    },
  },
  Circle_div: {
    position: "absolute",
    left: 0,
  },
  Main_div: {
    width: "100%",
    height: "700px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
  },
  Title: {
    fontSize: "35px",
    lineHeight: 1.2,
    fontWeight: 500,
    color: "rgba(0, 62, 106, 1)",
    paddingRight: "130px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "25px",
      paddingRight: "0px",
      marginTop: "100px",
    },
  },
  Para_div: {
    fontSize: "15px",
    fontWeight: 400,
    paddingRight: "100px",
    marginTop: "50px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px",
      paddingRight: "0px",
      marginTop: "20px",
    },
  },
  Pdf_div: {
    width: "240px",
    display: "flex",
    alignItems: "center",
    marginTop: "50px",
    cursor: "pointer",
    border: "2px solid rgba(0, 62, 106, 1)",
    borderRadius: "5px",
    padding: "10px",
    fontSize: "19px",
    fontWeight: 500,
    [theme.breakpoints.down("xs")]: {
      marginTop: "20px",
      width: "230px",
    },
  },
  Drop_icon: {
    background: "green",
    width: "25px",
    height: "25px",
    borderRadius: "50% 0 50% 50%",
    transform: "rotate(-45deg)",
    marginRight: "10px",
  },
  Image_div: {
    [theme.breakpoints.down("xs")]: {
      width: "200px",
      marginTop: "30px",
    },
  },
  Hero_video: {
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      height: "500px",
      objectFit: "cover",
    },
  },
  Hero_text_div: {
    width: "100%",
    height: "100%",
    position: "absolute",
    top: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      top: "-120px",
    },
  },
  Hero_Title: {
    fontSize: "65px",
    fontWeight: 600,
    lineHeight: 1,
    color: "white",
    [theme.breakpoints.down("xs")]: {
      fontSize: "35px",
    },
  },
  Hero_title_text: {
    fontSize: "35px",
    fontWeight: 500,
    lineHeight: 1.2,
    color: "white",
    padding: "0px 500px",
    marginTop: "20px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "20px",
      padding: "0px",
      marginTop: "0px",
    },
  },
  Main_container: {
    width: "100%",
    height: "100%",
    marginTop: "100px",
    paddingTop: "50px",
    background: "linear-gradient(180deg, #FFFFFF 50.04%, #D7EEFF 103.28%)",
    [theme.breakpoints.down("xs")]: {
      marginTop: "0px",
      paddingTop: "0px",
    },
  },
}));

const Aura = () => {
  const classes = useStyles();
  window.scrollTo(0, 0);

  return (
    <div>
      <Header />
      <CarouselImage />
      <motion.div variants={TitleText} initial="initial" animate="animate">
        <div className={classes.Hero_text_div}>
          <motion.span variants={FadeIn("down")} className={classes.Hero_Title}>
            AURA
          </motion.span>
          <motion.span
            variants={FadeIn("down")}
            className={classes.Hero_title_text}
          >
            Software Solution for supporting digitalization of the water utility
            processes.
          </motion.span>
        </div>
      </motion.div>
      <div className={classes.Main_container}>
        <div className={classes.Circle_div}>
          <motion.img
            variants={Circle}
            initial="initial"
            animate="animate"
            src={Right}
            alt="img"
            style={{ width: "130px" }}
          />
        </div>
        <Container maxWidth="lg">
          <Grid container>
            <Grid item lg={8} md={8} xs={12}>
              <div>
                <div>
                  <h1 className={classes.Title}>
                    <span style={{ color: "#00A859" }}>
                      AURA - Software Solution&nbsp;
                    </span>
                    To Support Manual Or Remote Meter Reading
                  </h1>
                </div>
                <div className={classes.Para_div}>
                  <p>
                    Aura Is A Software Solution With Different Mobile
                    Applications Which Are Designed To Support Manual Or Remote
                    Meter Reading, Modul Programming And Installation, Inventory
                    List Of Watermeters And Creating Variety Of Analysis.
                  </p>
                </div>
                <a href={Aura1} target="_blank" rel="noreferrer">
                  <div className={classes.Pdf_div}>
                    <div className={classes.Drop_icon}></div>
                    AURA Catalogue
                  </div>
                </a>
              </div>
            </Grid>
            <Grid item lg={4} md={4} xs={12}>
              <div>
                <img src={Main_Aura} alt="img" className={classes.Image_div} />
              </div>
            </Grid>
          </Grid>
        </Container>
        <div>
          <MobileApp />
        </div>
        <div>
          <MillionDiv />
        </div>
      </div>
      <div>
        <Footer />
        <CopyRight />
      </div>
    </div>
  );
};

export default Aura;
