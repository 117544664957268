import React, { useState, useEffect, useRef } from "react";
import Globe from "react-globe.gl";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const World = () => {
  const globeEl = useRef();
  const theme = useTheme();
  const Mobile = useMediaQuery(theme.breakpoints.down("xs"));
  const data = [
    {
      name: "Slovenia",
      lat: 46.151241,
      lng: 14.995463,
    },
    {
      name: "Croatia",
      lat: 45.1,
      lng: 15.2,
    },
    {
      name: "Bosnia",
      lat: 43.915886,
      lng: 17.679076,
    },
    {
      name: "Montenegro",
      lat: 42.708678,
      lng: 19.37439,
    },
    {
      name: "Serbia",
      lat: 44.016521,
      lng: 21.005859,
    },
    {
      name: "Romania",
      lat: 45.943161,
      lng: 24.96676,
    },
    {
      name: "Bulgaria",
      lat: 42.733883,
      lng: 25.48583,
    },
    {
      name: "Greece",
      lat: 39.074208,
      lng: 21.824312,
    },
    {
      name: "Australia",
      lat: -25.274398,
      lng: 133.775136,
    },
    {
      name: "New Zealand",
      lat: -40.900557,
      lng: 174.885971,
    },
  ];

  useEffect(() => {
    globeEl.current.pointOfView({
      lat: 44.016521,
      lng: 21.005859,
      altitude: 2,
    });
  }, []);

  return (
    // <div style={{ width: "500px", height: "864px" }}>
    <Globe
      ref={globeEl}
      globeImageUrl="//unpkg.com/three-globe/example/img/earth-night.jpg"
      backgroundColor="white"
      width={Mobile ? 330 : 800}
      // width={"auto"}
      labelsData={data}
      labelLat={(d) => d.lat}
      labelLng={(d) => d.lng}
      labelText={(d) => d.name}
      labelSize={() => "1"}
      labelDotRadius={() => "1"}
      labelColor={() => "rgba(255, 165, 0, 0.75)"}
      labelResolution={2}
    />
    // </div>
  );
};

export default World;
