import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@mui/material/Container";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { TitleText, FadeIn } from "../../Animation/TitleText";
import { motion } from "framer-motion";

const useStyles = makeStyles((theme) => ({
  Root: {
    position: "absolute",
    //  zIndex: "11",
    top: "0",
    bottom: "0",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    // marginTop: "280px",
  },
  Mobile_Root: {
    // zIndex: "11",
    position: "absolute",
    width: "100%",
    marginTop: "-175px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  Main_container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    color: "rgba(255, 255, 255, 1)",
  },
  Sub_title: { fontSize: "22px", fontWeight: 500 },
  Mobile_Sub_title: { fontSize: "15px", fontWeight: 500 },
  Title: {
    fontSize: "65px",
    fontWeight: 600,
    lineHeight: 1,
  },
  Mobile_Title: {
    fontSize: "35px",
    textAlign: "center",
    fontWeight: 600,
    lineHeight: 1,
  },
  Para_div: {
    fontSize: "15px",
    padding: "0px 325px",
    textAlign: "center",
    marginTop: "10px",
  },
  Mobile_Para_div: {
    fontSize: "12px",
    textAlign: "center",
    marginTop: "5px",
  },
  Aura_button: {
    backgroundColor: "white !important",
    color: "rgba(0, 62, 106, 1) !important",
    padding: "7px !important",
    fontSize: "17px !important",
    fontWeight: "600 !important",
    width: "170px !important",
    textTransform: "none !important",
    borderRadius: "2px !important",
    marginTop: "20px !important",
    "&:hover": {
      background: "rgba(0, 62, 106, 1) !important",
      color: "white !important",
    },
  },
  Mobile_Aura_button: {
    backgroundColor: "white !important",
    color: "rgba(0, 62, 106, 1) !important",
    padding: "4px !important",
    fontSize: "14px !important",
    fontWeight: "600 !important",
    width: "135px !important",
    textTransform: "none !important",
    borderRadius: "2px !important",
    marginTop: "20px !important",
    "&:hover": {
      background: "rgba(0, 62, 106, 1) !important",
      color: "white !important",
    },
  },
}));

const MeasurementText = ({ Titletext, Title }) => {
  const classes = useStyles();
  const theme = useTheme();
  const Mobile = useMediaQuery(theme.breakpoints.down("xs"));

  const [scroll_active, setScroll_Active] = React.useState();
  const changebg = () => {
    if (window.scrollY >= 200) {
      setScroll_Active(true);
    } else {
      setScroll_Active(false);
    }
  };
  window.addEventListener("scroll", changebg);

  return (
    <>
      {Mobile ? (
        <>
          {scroll_active ? (
            ""
          ) : (
            <div className={classes.Mobile_Root}>
              <Container maxWidth="lg">
                <motion.div
                  variants={TitleText}
                  initial="initial"
                  animate="animate"
                  className={classes.Main_container}
                >
                  <motion.span
                    variants={FadeIn("down")}
                    className={classes.Mobile_Title}
                  >
                    {Title}
                  </motion.span>
                </motion.div>
              </Container>
            </div>
          )}
        </>
      ) : (
        <>
          <div>
            {scroll_active ? (
              ""
            ) : (
              <div className={classes.Root}>
                <Container maxWidth="lg">
                  <motion.div
                    variants={TitleText}
                    initial="initial"
                    animate="animate"
                    className={classes.Main_container}
                  >
                    <motion.span
                      variants={FadeIn("down")}
                      className={classes.Title}
                    >
                      {Title}
                    </motion.span>
                  </motion.div>
                </Container>
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default MeasurementText;
