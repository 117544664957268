import { Variants } from "framer-motion";

export const FadeIn = (direction: "up" | "down" = "up"): Variants => {
  return {
    initial: {
      y: direction === "up" ? 40 : -60,
      opacity: 0,
    },
    animate: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 1.0,
        ease: "easeInOut",
      },
    },
  };
};

export const TitleText: Variants = {
  initial: {},
  animate: {
    transition: {
      staggerChildren: 2.0,
      delayChildren: 0.5,
    },
  },
};
