import React, { useState } from "react";
import Card from "@mui/material/Card";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { makeStyles } from "@material-ui/core/styles";

import Bluewater from "../../assets/Images/Bluewater.png";
import Blue_1 from "../../assets/Images/Blue_1.png";
import Blue_2 from "../../assets/Images/Blue_2.png";
import Blue_3 from "../../assets/Images/Blue_3.png";
import Blue_4 from "../../assets/Images/Blue_4.png";
import Blue_5 from "../../assets/Images/Blue_5.png";
import White_1 from "../../assets/Images/White_1.png";
import White_2 from "../../assets/Images/White_2.png";
import White_3 from "../../assets/Images/White_3.png";
import White_4 from "../../assets/Images/White_4.png";
import White_5 from "../../assets/Images/White_5.png";

const useStyles = makeStyles((theme) => ({
  Root: {
    width: "100%",
    marginTop: "240px",
    height: "700px",
    [theme.breakpoints.down("xs")]: {
      marginTop: "150px",
      height: "680px",
    },
  },
  Image_div: {
    backgroundImage: `url(${Bluewater})`,
    width: "100%",
    height: "680px",
    backgroundSize: "100% 100%",
    [theme.breakpoints.down("xs")]: {
      height: "630px",
      backgroundSize: "cover",
    },
  },
  Main_container: {
    display: "flex",
  },
  Main_Title: {
    fontSize: "40px",
    color: "rgba(0, 62, 106, 1)",
    fontWeight: 600,
    textAlign: "center",
    paddingTop: "80px",
    marginBottom: "70px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "27px",
      marginBottom: "40px",
      paddingTop: "50px",
    },
  },
  Main_card: {
    width: "310px",
    height: "350px",
    boxShadow: "0px 5px 17px #003E6A !important",
    borderRadius: "10px !important",
    marginLeft: "30px",
    marginBottom: "20px",
    [theme.breakpoints.down("xs")]: {
      width: "330px !important",
    },
  },
  Main_card01: {
    width: "310px",
    height: "350px",
    boxShadow: "0px 5px 17px #003E6A !important",
    borderRadius: "10px !important",
    marginLeft: "30px",
    marginBottom: "20px",
    marginTop: "50px",
    [theme.breakpoints.down("xs")]: {
      marginTop: "0px",
      width: "330px !important",
    },
  },
  Main_card2: {
    width: "310px",
    height: "350px",
    boxShadow: "0px 5px 17px #003E6A !important",
    borderRadius: "10px !important",
    marginLeft: "30px",
    marginBottom: "10px",
    backgroundColor: "rgba(0, 62, 106, 1) !important",
    [theme.breakpoints.down("xs")]: {
      width: "330px !important",
    },
  },
  Main_card02: {
    width: "310px",
    height: "350px",
    boxShadow: "0px 5px 17px #003E6A !important",
    borderRadius: "10px !important",
    marginLeft: "30px",
    marginBottom: "10px",
    backgroundColor: "rgba(0, 62, 106, 1) !important",
    marginTop: "50px",
    [theme.breakpoints.down("xs")]: {
      marginTop: "0px",
      width: "330px !important",
    },
  },
  Card_container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  Card_container2: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  card_image: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "20px",
    borderRadius: "60px",
    width: "80px",
    height: "80px",
    backgroundColor: "rgba(0, 62, 106, 1)",
  },
  card_image2: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "20px",
    borderRadius: "60px",
    width: "80px",
    height: "80px",
    backgroundColor: "white",
  },
  Title_div: {
    color: "rgba(0, 62, 106, 1)",
    fontSize: "18px",
    fontWeight: 500,
    marginBottom: "0px",
    marginTop: "30px",
  },
  Title_div2: {
    color: "white",
    fontSize: "18px",
    fontWeight: 500,
    marginBottom: "0px",
    marginTop: "30px",
  },
  text_div: {
    padding: "15px 37px",
    fontSize: "15px",
    textAlign: "center",
  },
  text_div2: {
    padding: "15px 37px",
    fontSize: "15px",
    textAlign: "center",
    color: "white",
  },
}));

const ScrollCards = () => {
  const classes = useStyles();

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
    },
    laptop: {
      breakpoint: { max: 1536, min: 700 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const [hovered, setHovered] = useState(false);
  const [hovered2, setHovered2] = useState(false);
  const [hovered3, setHovered3] = useState(false);
  const [hovered4, setHovered4] = useState(false);
  const [hovered5, setHovered5] = useState(false);
  const [hovered6, setHovered6] = useState(false);

  const blueHover = () => setHovered(true);
  const blueHover2 = () => setHovered2(true);
  const blueHover3 = () => setHovered3(true);
  const blueHover4 = () => setHovered4(true);
  const blueHover5 = () => setHovered5(true);
  const blueHover6 = () => setHovered6(true);

  const whiteHover = () => setHovered(false);
  const whiteHover2 = () => setHovered2(false);
  const whiteHover3 = () => setHovered3(false);
  const whiteHover4 = () => setHovered4(false);
  const whiteHover5 = () => setHovered5(false);
  const whiteHover6 = () => setHovered6(false);

  return (
    <>
      <div className={classes.Root}>
        <div className={classes.Image_div}>
          <div className={classes.Main_Title}>
            <span>What Can We Do For You?</span>
          </div>
          <Carousel
            infinite={true}
            autoPlay={true}
            autoPlaySpeed={5000}
            responsive={responsive}
            arrows={false}
          >
            <div>
              <div onMouseEnter={blueHover} onMouseLeave={whiteHover}>
                {hovered ? (
                  <>
                    <Card className={classes.Main_card2}>
                      <div className={classes.Card_container2}>
                        <div className={classes.card_image2}>
                          <img src={Blue_1} alt="img" />
                        </div>
                        <div className={classes.Title_div2}>
                          <span>Measure Smarter</span>
                        </div>
                        <div className={classes.text_div2}>
                          <span>
                            Among our portfolio we can provide with the best
                            solution for your needs. Watermeter needs to suit
                            your expectations.
                          </span>
                        </div>
                      </div>
                    </Card>
                  </>
                ) : (
                  <>
                    <Card className={classes.Main_card}>
                      <div className={classes.Card_container}>
                        <div className={classes.card_image}>
                          <img src={White_1} alt="img" />
                        </div>
                        <div className={classes.Title_div}>
                          <span>Measure Smarter</span>
                        </div>
                        <div className={classes.text_div}>
                          <span>
                            Among our portfolio we can provide with the best
                            solution for your needs. Watermeter needs to suit
                            your expectations.
                          </span>
                        </div>
                      </div>
                    </Card>
                  </>
                )}
              </div>
            </div>
            <div>
              <div onMouseEnter={blueHover2} onMouseLeave={whiteHover2}>
                {hovered2 ? (
                  <>
                    <Card className={classes.Main_card02}>
                      <div className={classes.Card_container2}>
                        <div className={classes.card_image2}>
                          <img src={Blue_2} alt="img" />
                        </div>
                        <div className={classes.Title_div2}>
                          <span>Efficient Use</span>
                        </div>
                        <div className={classes.text_div2}>
                          <span>
                            We can teach you how to mount the watermeter and use
                            it on most efficient way.
                          </span>
                        </div>
                      </div>
                    </Card>
                  </>
                ) : (
                  <>
                    <Card className={classes.Main_card01}>
                      <div className={classes.Card_container}>
                        <div className={classes.card_image}>
                          <img src={White_2} alt="img" />
                        </div>
                        <div className={classes.Title_div}>
                          <span>Efficient Use</span>
                        </div>
                        <div className={classes.text_div}>
                          <span>
                            We can teach you how to mount the watermeter and use
                            it on most efficient way.
                          </span>
                        </div>
                      </div>
                    </Card>
                  </>
                )}
              </div>
            </div>
            <div>
              <div onMouseEnter={blueHover3} onMouseLeave={whiteHover3}>
                {hovered3 ? (
                  <>
                    <Card className={classes.Main_card2}>
                      <div className={classes.Card_container2}>
                        <div className={classes.card_image2}>
                          <img src={Blue_3} alt="img" />
                        </div>
                        <div className={classes.Title_div2}>
                          <span>Fast Data Collection</span>
                        </div>
                        <div className={classes.text_div2}>
                          <span>
                            Get the data you need and when it is needed on the
                            fastest and most transparent way from many
                            endpoints.
                          </span>
                        </div>
                      </div>
                    </Card>
                  </>
                ) : (
                  <>
                    <Card className={classes.Main_card}>
                      <div className={classes.Card_container}>
                        <div className={classes.card_image}>
                          <img src={White_3} alt="img" />
                        </div>
                        <div className={classes.Title_div}>
                          <span>Fast Data Collection</span>
                        </div>
                        <div className={classes.text_div}>
                          <span>
                            Get the data you need and when it is needed on the
                            fastest and most transparent way from many
                            endpoints.
                          </span>
                        </div>
                      </div>
                    </Card>
                  </>
                )}
              </div>
            </div>
            <div>
              <div onMouseEnter={blueHover4} onMouseLeave={whiteHover4}>
                {hovered4 ? (
                  <>
                    <Card className={classes.Main_card02}>
                      <div className={classes.Card_container2}>
                        <div className={classes.card_image2}>
                          <img src={Blue_4} alt="img" />
                        </div>
                        <div className={classes.Title_div2}>
                          <span>Water Loss Detection</span>
                        </div>
                        <div className={classes.text_div2}>
                          <span>
                            With smart water system you can measure: flow, water
                            leaks, un-metered water etc.
                          </span>
                        </div>
                      </div>
                    </Card>
                  </>
                ) : (
                  <>
                    <Card className={classes.Main_card01}>
                      <div className={classes.Card_container}>
                        <div className={classes.card_image}>
                          <img src={White_4} alt="img" />
                        </div>
                        <div className={classes.Title_div}>
                          <span>Water Loss Detection</span>
                        </div>
                        <div className={classes.text_div}>
                          <span>
                            With smart water system you can measure: flow, water
                            leaks, un-metered water etc.
                          </span>
                        </div>
                      </div>
                    </Card>
                  </>
                )}
              </div>
            </div>
            <div>
              <div onMouseEnter={blueHover5} onMouseLeave={whiteHover5}>
                {hovered5 ? (
                  <>
                    <Card className={classes.Main_card2}>
                      <div className={classes.Card_container2}>
                        <div className={classes.card_image2}>
                          <img src={Blue_5} alt="img" />
                        </div>
                        <div className={classes.Title_div2}>
                          <span>Data Analysis</span>
                        </div>
                        <div className={classes.text_div2}>
                          <span>
                            To resolve problems faster in order to improve
                            efficiency, you need to analyze data easier.
                          </span>
                        </div>
                      </div>
                    </Card>
                  </>
                ) : (
                  <>
                    <Card className={classes.Main_card}>
                      <div className={classes.Card_container}>
                        <div className={classes.card_image}>
                          <img src={White_5} alt="img" />
                        </div>
                        <div className={classes.Title_div}>
                          <span>Data Analysis</span>
                        </div>
                        <div className={classes.text_div}>
                          <span>
                            To resolve problems faster in order to improve
                            efficiency, you need to analyze data easier.
                          </span>
                        </div>
                      </div>
                    </Card>
                  </>
                )}
              </div>
            </div>
            <div>
              <div onMouseEnter={blueHover6} onMouseLeave={whiteHover6}>
                {hovered6 ? (
                  <>
                    <Card className={classes.Main_card02}>
                      <div className={classes.Card_container2}>
                        <div className={classes.card_image2}>
                          <img src={Blue_3} alt="img" />
                        </div>
                        <div className={classes.Title_div2}>
                          <span>Process and Assets Management</span>
                        </div>
                        <div className={classes.text_div2}>
                          <span>
                            Supporting to improve field workforce management and
                            having exact overview of your assets.
                          </span>
                        </div>
                      </div>
                    </Card>
                  </>
                ) : (
                  <>
                    <Card className={classes.Main_card01}>
                      <div className={classes.Card_container}>
                        <div className={classes.card_image}>
                          <img src={White_3} alt="img" />
                        </div>
                        <div className={classes.Title_div}>
                          <span>Process and Assets Management</span>
                        </div>
                        <div className={classes.text_div}>
                          <span>
                            Supporting to improve field workforce management and
                            having exact overview of your assets.
                          </span>
                        </div>
                      </div>
                    </Card>
                  </>
                )}
              </div>
            </div>
          </Carousel>
        </div>
      </div>
    </>
  );
};

export default ScrollCards;
