import React from "react";
import Header from "../../component/Header";
import Footer from "../../component/Footer";
import CopyRight from "../../component/CopyRight";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import { makeStyles } from "@material-ui/core/styles";
import { TitleText, FadeIn } from "../../Animation/TitleText";

import Left_2 from "../../assets/Images/Left_2.png";
import Right from "../../assets/Images/Right.png";

import { motion } from "framer-motion";
import { Circle } from "../../Animation/Circle";

import Hero_img from "../../assets/Images/Communication/Hero_img.jpg";
import Wired_img from "../../assets/Images/Communication/Wired_img.png";
import Wireless_img from "../../assets/Images/Communication/Wireless_img.png";

import HRI from "../../pdf/Communication/Wired/HRI.pdf";
import HRI_MEI from "../../pdf/Communication/Wired/HRI_MEI.pdf";
import OD from "../../pdf/Communication/Wired/OD.pdf";
import RD from "../../pdf/Communication/Wired/RD.pdf";

import ECHOPulser from "../../pdf/Communication/Wireless/ECHOPulser.pdf";
import HydroPulsar_En from "../../pdf/Communication/Wireless/HydroPulsar(En).pdf";
import HydroPulsar_SRB from "../../pdf/Communication/Wireless/HydroPulsar(SRB).pdf";
import HydroPulsar_SLO from "../../pdf/Communication/Wireless/HydroPulsar(SLO).pdf";
import PulseRF from "../../pdf/Communication/Wireless/PulseRF.pdf";
import ECHORep from "../../pdf/Communication/Wireless/ECHORep.pdf";
// import ECHOSphere from "../../pdf/Communication/Wireless/ECHOSphere.pdf";
import GasPulsar from "../../pdf/Communication/Wireless/GasPulsar.pdf";
import RepeaterRF from "../../pdf/Communication/Wireless/RepeaterRF.pdf";
import GatewayRF from "../../pdf/Communication/Wireless/GatewayRF.pdf";
import ECHOLob from "../../pdf/Communication/Wireless/ECHOLob.pdf";

import Lora from "../../assets/Logo/Lora.png";
import Lorawan from "../../assets/Logo/Lorawan.png";
import Oms from "../../assets/Logo/Oms.jpg";
import Mbus from "../../assets/Logo/Mbus.png";
import NB_Iot from "../../assets/Logo/NB_Iot.png";

const useStyles = makeStyles((theme) => ({
  Data_div: {
    fontWeight: 500,
    fontSize: "19px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
    },
  },
  Container_1: {
    width: "100%",
    height: "700px",
    paddingTop: "80px",
    position: "relative",
    [theme.breakpoints.down("xs")]: {
      paddingTop: "50px",
      height: "100%",
    },
  },
  Container_2: {
    width: "100%",
    height: "700px",
    paddingTop: "120px",
    position: "relative",
    [theme.breakpoints.down("xs")]: {
      paddingTop: "0px",
      height: "900px",
      paddingBottom: "px",
    },
  },
  Hero_image: {
    width: "100%",
    height: "700px",
    // marginTop: "100px",
    [theme.breakpoints.down("xs")]: {
      height: "500px",
      objectFit: "cover",
      position: "relative",
    },
  },
  Image_1: {
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      marginTop: "11rem",
    },
  },
  Image_2: {
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  Title: {
    fontSize: "35px",
    lineHeight: 1.2,
    fontWeight: 600,
    color: "rgba(0, 62, 106, 1)",
    marginTop: "10px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "25px",
      textAlign: "left",
      marginTop: "50px",
    },
  },
  Drop: {
    background: "green",
    width: "25px",
    height: "25px",
    borderRadius: "50% 0 50% 50%",
    transform: "rotate(-45deg)",
    marginRight: "10px",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      marginTop: "5px",
    },
  },
  Drop2: {
    background: "green",
    width: "25px",
    height: "25px",
    borderRadius: "50% 0 50% 50%",
    transform: "rotate(-45deg)",
    marginRight: "10px",
    marginLeft: "30px",
    [theme.breakpoints.down("xs")]: {
      marginLeft: "30px",
    },
  },
  Drop_Main_div: { display: "flex", flexDirection: "row" },
  Drop_1_div: {
    width: "240px",
    display: "flex",
    alignItems: "center",
    marginTop: "10px",
    cursor: "pointer",
    border: "2px solid rgba(0, 62, 106, 1)",
    borderRadius: "5px",
    padding: "10px",
    // color: "rgba(0, 62, 106, 1)",
    [theme.breakpoints.down("xs")]: {
      marginTop: "10px",
      width: "165px",
    },
  },
  Drop_div: {
    width: "240px",
    display: "flex",
    alignItems: "center",
    marginTop: "30px",
    cursor: "pointer",
    border: "2px solid rgba(0, 62, 106, 1)",
    borderRadius: "5px",
    padding: "10px",
    // color: "rgba(0, 62, 106, 1)",
    [theme.breakpoints.down("xs")]: {
      marginTop: "40px",
      width: "165px",
    },
  },
  Drop_2_div: {
    marginLeft: "50px",
    [theme.breakpoints.down("xs")]: {
      marginLeft: "20px",
    },
  },
  Hero_text_div: {
    position: "absolute",
    zIndex: 1,
    width: "100%",
    height: "80%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    textAlign: "center",
  },
  Hero_Title: {
    fontSize: "65px",
    fontWeight: 600,
    lineHeight: 1,
    color: "white",
    [theme.breakpoints.down("xs")]: {
      fontSize: "35px",
    },
  },
}));

const Communication = () => {
  const classes = useStyles();
  // window.scrollTo(0, 0);
  return (
    <>
      <div>
        <Header />
        <motion.div
          variants={TitleText}
          initial="initial"
          animate="animate"
          className={classes.Hero_text_div}
        >
          <motion.span variants={FadeIn("down")} className={classes.Hero_Title}>
            Communication
          </motion.span>
        </motion.div>
        <div>
          <img src={Hero_img} alt="img" className={classes.Hero_image} />
        </div>
        <div
          style={{
            background: "linear-gradient(180deg, #FFFFFF 50%, #D7EEFF 103.28%)",
          }}
        >
          <div className={classes.Container_1}>
            <div style={{ position: "absolute" }}>
              <motion.img
                variants={Circle}
                initial="initial"
                animate="animate"
                src={Right}
                alt="img"
              />
            </div>
            <Container maxWidth="lg">
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  flexWrap: "wrap",
                  marginBottom: "50px",
                }}
              >
                <img
                  src={Lora}
                  alt="img"
                  style={{ width: "135px", height: "120px" }}
                />
                <img
                  src={Lorawan}
                  alt="img"
                  style={{ width: "190px", height: "130px" }}
                />
                <img
                  src={Oms}
                  alt="img"
                  style={{ width: "140px", height: "110px", marginTop: "20px" }}
                />
                <img
                  src={Mbus}
                  alt="img"
                  style={{ width: "180px", height: "110px", marginTop: "10px" }}
                />
                <img
                  src={NB_Iot}
                  alt="img"
                  style={{ width: "180px", height: "70px", marginTop: "23px" }}
                />
              </div>
              <Grid container>
                <Grid item lg={6} md={6} xs={12} order={{ xs: 3, sm: 2 }}>
                  <div style={{ marginTop: "50px" }}>
                    <div>
                      <h1 className={classes.Title}>
                        <span style={{ color: "#00A859" }}>Wireless&nbsp;</span>
                        Communication
                      </h1>
                    </div>
                    <div className={classes.Data_div}>
                      <div className={classes.Drop_Main_div}>
                        <div>
                          <a href={ECHOLob} target="_blank" rel="noreferrer">
                            <div className={classes.Drop_div}>
                              <div className={classes.Drop}></div>
                              ECHOLob
                            </div>
                          </a>
                          <a href={ECHORep} target="_blank" rel="noreferrer">
                            <div className={classes.Drop_1_div}>
                              <div className={classes.Drop}></div>
                              ECHORep
                            </div>
                          </a>
                          <a href={GatewayRF} target="_blank" rel="noreferrer">
                            <div className={classes.Drop_div}>
                              <div className={classes.Drop}></div>
                              Gateway RF
                            </div>
                          </a>
                          <a href={RepeaterRF} target="_blank" rel="noreferrer">
                            <div className={classes.Drop_div}>
                              <div className={classes.Drop}></div>
                              Repeater RF
                            </div>
                          </a>
                          <a href={PulseRF} target="_blank" rel="noreferrer">
                            <div className={classes.Drop_div}>
                              <div className={classes.Drop}></div>
                              Pulse RF
                            </div>
                          </a>
                        </div>
                        <div className={classes.Drop_2_div}>
                          {/*<a href={ECHOSphere} target="_blank" rel="noreferrer">
                            <div className={classes.Drop_div}>
                              <div className={classes.Drop}></div>
                              ECHOSphere
                            </div>
                          </a>*/}
                          <a
                            href={HydroPulsar_SLO}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <div className={classes.Drop_div}>
                              <div className={classes.Drop}></div>
                              HydroPulsar (SLO)
                            </div>
                          </a>
                          <a
                            href={HydroPulsar_En}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <div className={classes.Drop_div}>
                              <div className={classes.Drop}></div>
                              HydroPulsar (En)
                            </div>
                          </a>
                          <a
                            href={HydroPulsar_SRB}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <div className={classes.Drop_div}>
                              <div className={classes.Drop}></div>
                              HydroPulsar (SRB)
                            </div>
                          </a>
                          <a href={GasPulsar} target="_blank" rel="noreferrer">
                            <div className={classes.Drop_div}>
                              <div className={classes.Drop}></div>
                              GasPulsar
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </Grid>
                <Grid item lg={6} md={6} xs={12} order={{ xs: 2, sm: 3 }}>
                  <div>
                    <img
                      src={Wireless_img}
                      alt="img"
                      className={classes.Image_2}
                    />
                  </div>
                </Grid>
              </Grid>
            </Container>
          </div>
          <div className={classes.Container_2}>
            <div style={{ position: "absolute", right: 0 }}>
              <motion.img
                variants={Circle}
                initial="initial"
                animate="animate"
                src={Left_2}
                alt="img"
              />
            </div>
            <Container maxWidth="lg">
              <Grid container>
                <Grid item lg={6} md={6} xs={12}>
                  <div>
                    <img
                      src={Wired_img}
                      alt="img"
                      className={classes.Image_1}
                    />
                  </div>
                </Grid>
                <Grid item lg={6} md={6} xs={12}>
                  <div>
                    <div>
                      <h1 className={classes.Title}>
                        <span style={{ color: "#00A859" }}>Wired&nbsp;</span>
                        Communication
                      </h1>
                    </div>
                    <div className={classes.Data_div}>
                      <div className={classes.Drop_Main_div}>
                        <div>
                          <a href={HRI} target="_blank" rel="noreferrer">
                            <div className={classes.Drop_1_div}>
                              <div className={classes.Drop}></div>
                              HRI Sensor
                            </div>
                          </a>
                          <a href={OD} target="_blank" rel="noreferrer">
                            <div className={classes.Drop_div}>
                              <div className={classes.Drop}></div>
                              HRI Optical Pulsar
                            </div>
                          </a>
                          <a href={ECHOPulser} target="_blank" rel="noreferrer">
                            <div className={classes.Drop_1_div}>
                              <div className={classes.Drop}></div>
                              ECHOPulser
                            </div>
                          </a>
                        </div>
                        <div className={classes.Drop_2_div}>
                          <a href={HRI_MEI} target="_blank" rel="noreferrer">
                            <div className={classes.Drop_1_div}>
                              <div className={classes.Drop}></div>
                              HRI-Mei
                            </div>
                          </a>
                          <a href={RD} target="_blank" rel="noreferrer">
                            <div className={classes.Drop_div}>
                              <div className={classes.Drop}></div>
                              HRI Pulser
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Container>
          </div>
        </div>
        <div>
          <Footer />
          <CopyRight />
        </div>
      </div>
    </>
  );
};

export default Communication;
