import React, { useEffect } from "react";
import Container from "@mui/material/Container";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@mui/material/Grid";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import AOS from "aos";
import "aos/dist/aos.css";
import Man from "../../assets/Images/Man.png";

const useStyles = makeStyles(() => ({
  Root: {
    width: "100%",
    height: "230px",
    background: "linear-gradient(91.62deg, #00A859 -21.38%, #006535 124.2%)",
    borderRadius: "10px",
    marginTop: "100px",
    display: "flex",
    flexDirection: "row",
    overflowX: "hidden",
  },
  Mobile_Root: {
    width: "100%",
    height: "500px",
    background: "linear-gradient(91.62deg, #00A859 -21.38%, #006535 124.2%)",
    borderRadius: "10px",
    marginTop: "100px",
    display: "flex",
    flexDirection: "column",
    position: "relative",
    overflowX: "hidden",
  },
  Image_div: { display: "flex", alignItems: "end", marginLeft: "50px" },
  Mobile_Image_div: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "20px",
    position: "absolute",
    bottom: 0,
    right: 50,
    left: 50,
  },
  Middle_div: {
    display: "flex",
    alignItems: "center",
    color: "white",
    fontSize: "40px",
    marginLeft: "70px",
    marginRight: "130px",
    lineHeight: 1.3,
  },
  Mobile_Middle_div: {
    fontSize: "25px",
    fontWeight: 500,
    lineHeight: 1.3,
    color: "white",
    textAlign: "center",
    marginTop: "30px",
  },
  End_div: {
    display: "flex",
    flexDirection: "column",
    textAlign: "left",
    justifyContent: "center",
    color: "white",
    // marginRight: "-40px",
    lineHeight: 1,
  },
  Mobile_End_div: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    color: "white",
    lineHeight: 1,
    marginTop: "30px",
  },
  Number_span: {
    fontSize: "140px",
    fontWeight: 600,
    marginBottom: "0px",
    paddingBottom: "0px",
    lineHeight: 1,
  },
  Mobile_Number_span: {
    fontSize: "120px",
    fontWeight: 600,
    marginBottom: "0px",
    paddingBottom: "0px",
    lineHeight: 1,
  },
}));

const AddDiv = () => {
  const classes = useStyles();
  const theme = useTheme();
  const Mobile = useMediaQuery(theme.breakpoints.down("xs"));

  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div>
      {Mobile ? (
        <>
          <Container maxWidth="lg">
            <div className={classes.Mobile_Root}>
              <div className={classes.Mobile_Middle_div}>
                <span>How Much of Your Water is Non-Revenue?</span>
              </div>
              <div className={classes.Mobile_End_div}>
                <div>
                  <span className={classes.Mobile_Number_span}>24%</span>
                </div>
                <div>
                  <span>Per Year</span>
                </div>
              </div>
              <div className={classes.Mobile_Image_div}>
                <img src={Man} alt="man" />
              </div>
            </div>
          </Container>
        </>
      ) : (
        <>
          <Container maxWidth="lg">
            <div className={classes.Root}>
              <div className={classes.Image_div}>
                <img src={Man} alt="man" />
              </div>
              <div className={classes.Middle_div}>
                <span>How Much of Your Water is Non-Revenue?</span>
              </div>
              <div className={classes.End_div} data-aos="fade-left">
                <span className={classes.Number_span}>24%</span>
                <span>Per Year</span>
              </div>
            </div>
          </Container>
        </>
      )}
    </div>
  );
};

export default AddDiv;
