import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@mui/material/Container";
import { motion } from "framer-motion";
import { Circle } from "../../Animation/Circle";
import CountUp from "react-countup";
import Grid from "@mui/material/Grid";
import VisibilitySensor from "react-visibility-sensor";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
// import Right from "../../assets/Images/Right.png";
import Right from "../../assets/Images/Right.png";
import UnderMain from "../../assets/Images/under_maintenance.png";

const useStyles = makeStyles((theme) => ({
  Root: {
    width: "100%",
    height: "100%",
    paddingTop: "50px",
    paddingBottom: "80px",
    [theme.breakpoints.down("xs")]: {
      paddingTop: "0px",
    },
  },
  Circle_div: {
    position: "absolute",
    left: 0,
    marginTop: "-40px",
  },
  Box: {
    height: "260px",
    textAlign: "center",
    margin: "0px 10px",
  },
  green2: {
    height: "100px",
    fontSize: "30px",
    fontWeight: 600,
    color: "#00A859",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  blue: {
    fontSize: "20px",
    fontWeight: 500,
    color: "#003E6A",
  },
  num_fontup: {
    fontSize: "larger",
  },
  Title_div: {
    display: "flex",
    justifyContent: "center",
  },
  Title_text: {
    fontSize: "35px",
    lineHeight: 1.2,
    fontWeight: 600,
    color: "rgba(0, 62, 106, 1)",
    [theme.breakpoints.down("xs")]: {
      fontSize: "25px",
      paddingRight: "0px",
    },
  },
  button_div: {
    color: "rgba(0, 62, 106, 1)",
    width: "350px",
    height: "60px",
    display: "flex",
    fontSize: "20px",
    background: "white",
    alignItems: "center",
    borderRadius: "50px",
    justifyContent: "center",
    border: "none",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "rgba(0, 62, 106, 1)",
      color: "white",
    },
    [theme.breakpoints.down("xs")]: {
      width: "300px",
    },
  },
  img: {
    height: "300px",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      height: "200px",
    },
  },
  dialog_div:{
    textAlign:"center",
    color:"black",
    fontSize:"20px",
    [theme.breakpoints.down("xs")]: {
      fontSize:"15px",
    },
  }
}));

const MillionDiv = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div className={classes.Root}>
      <div className={classes.Circle_div}>
        <motion.img
          variants={Circle}
          initial="initial"
          animate="animate"
          src={Right}
          alt="img"
          style={{ width: "130px" }}
        />
      </div>
      <Container maxWidth="lg" style={{ padding: "0px" }}>
        <div className={classes.Title_div}>
          <h1 className={classes.Title_text}>
            <span>Our Success</span>
          </h1>
        </div>
        <Grid container>
          <Grid md={2} xs={6} item>
            <div className={classes.Box}>
              <div className={classes.blue}>More than</div>
              <div className={classes.green2}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <CountUp start={0} end={6} duration={2} decimals={1}>
                    {({ countUpRef, start }) => (
                      <VisibilitySensor onChange={start}>
                        <span ref={countUpRef} className={classes.num_fontup} />
                      </VisibilitySensor>
                    )}
                  </CountUp>
                  <div>Million</div>
                </div>
              </div>
              <div className={classes.blue}>Archived readings</div>
            </div>
          </Grid>
          <Grid md={2} xs={6} item>
            <div className={classes.Box}>
              <div className={classes.blue}>More than</div>
              <div className={classes.green2}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <CountUp start={0} end={2} duration={2} decimals={1}>
                    {({ countUpRef, start }) => (
                      <VisibilitySensor onChange={start}>
                        <span ref={countUpRef} className={classes.num_fontup} />
                      </VisibilitySensor>
                    )}
                  </CountUp>
                  <div>Million</div>
                </div>
              </div>
              <div className={classes.blue}>Pictures</div>
            </div>
          </Grid>
          <Grid md={2} xs={6} item>
            <div className={classes.Box}>
              <div className={classes.blue}>More than</div>
              <div className={classes.green2}>
                <CountUp
                  start={0}
                  end={500}
                  duration={2}
                  decimals={3}
                  decimal="."
                >
                  {({ countUpRef, start }) => (
                    <VisibilitySensor onChange={start}>
                      <span ref={countUpRef} className={classes.num_fontup} />
                    </VisibilitySensor>
                  )}
                </CountUp>
              </div>
              <div className={classes.blue}>Annually sold meters</div>
            </div>
          </Grid>
          <Grid md={2} xs={6} item>
            <div className={classes.Box}>
              <div className={classes.blue}>Approximately</div>
              <div className={classes.green2}>
                <CountUp
                  start={0}
                  end={130}
                  duration={2}
                  decimals={3}
                  decimal="."
                >
                  {({ countUpRef, start }) => (
                    <VisibilitySensor onChange={start}>
                      <span ref={countUpRef} className={classes.num_fontup} />
                    </VisibilitySensor>
                  )}
                </CountUp>
              </div>
              <div className={classes.blue}>Connections to NB-IoT networks</div>
            </div>
          </Grid>
          <Grid md={2} xs={6} item>
            <div className={classes.Box}>
              <div className={classes.blue}>More than</div>
              <div className={classes.green2}>
                <CountUp
                  start={0}
                  end={40}
                  duration={2}
                  decimals={3}
                  decimal="."
                >
                  {({ countUpRef, start }) => (
                    <VisibilitySensor onChange={start}>
                      <span ref={countUpRef} className={classes.num_fontup} />
                    </VisibilitySensor>
                  )}
                </CountUp>
              </div>
              <div className={classes.blue}>Daily reads over AMI</div>
            </div>
          </Grid>
          <Grid md={2} xs={6} item>
            <div className={classes.Box}>
              <div className={classes.blue}>More than</div>
              <div className={classes.green2}>
                <CountUp start={0} end={500} duration={2} decimals={0}>
                  {({ countUpRef, start }) => (
                    <VisibilitySensor onChange={start}>
                      <span ref={countUpRef} className={classes.num_fontup} />
                    </VisibilitySensor>
                  )}
                </CountUp>
              </div>
              <div className={classes.blue}>Satisfied Users</div>
            </div>
          </Grid>
        </Grid>
        <div className={classes.Title_div}>
          <h1 className={classes.Title_text}>
            <button
              className={classes.button_div}
              onClick={() => {
                setOpen(true);
              }}
            >
              Case Studies
            </button>
          </h1>
        </div>
      </Container>
      {open ? (
        <Dialog
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          style={{paddingRight:"-17px !important"}}
        >
          <DialogContent>
            <div>
              <div className={classes.dialog_div}>Under Construction</div>
              <img src={UnderMain} className={classes.img} />
            </div>
          </DialogContent>
        </Dialog>
      ) : (
        ""  
      )}
    </div>
  );
};
export default MillionDiv;
