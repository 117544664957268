import { makeStyles } from "@material-ui/core/styles";
import Drops from "../../assets/Images/Drops.png";

const useStyles = makeStyles((theme) => ({
  Root: {
    width: "100%",
    marginTop: "-10px",
    height: "700px",
    background: "linear-gradient(100.59deg, #003E6A 0%, #002A48 100%)",
    overflowX: "hidden",
    [theme.breakpoints.down("xs")]: {
      height: "800px",
    },
  },
  Image_div: {
    backgroundImage: `url(${Drops})`,
    width: "100%",
    height: "700px",
    backgroundSize: "100% 100%",
    [theme.breakpoints.down("xs")]: {
      backgroundSize: "contain",
    },
  },
  MainContainer: {
    display: "flex !important",
    alignItems: "center !important",
    paddingTop: "140px !important",
    marginBottom: "128px !important",
    [theme.breakpoints.down("xs")]: {
      paddingTop: "50px !important",
    },
  },
  Textsection: {
    marginTop: "120px",
    marginBottom: "100px",
    color: "#D3D3D3",
  },
  Text_div: { color: "#D3D3D3" },
  Title_text: {
    fontSize: "37px",
    color: "#D3D3D3",
    lineHeight: 1.2,
    [theme.breakpoints.down("xs")]: {
      fontSize: "26px",
      marginTop: "30px",
    },
  },
  Icon_with_text: {
    display: "flex",
    alignItems: "center",
    fontWeight: 500,
    fontSize: "20px",
    marginBottom: "20px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "18px",
    },
  },
  Para_div: {
    fontSize: "15px",
    fontWeight: 400,
    paddingRight: "20px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "13px",
    },
  },
  Main_image: {
    width: "85%",
    [theme.breakpoints.down("xs")]: {
      width: "335px",
    },
  },
  Drop: {
    background: "green",
    width: "25px",
    height: "25px",
    borderRadius: "50% 0 50% 50%",
    transform: "rotate(-45deg)",
    marginRight: "10px",
  },
}));
export default useStyles;
