import React, { useEffect } from "react";
import Grid from "@mui/material/Grid";
import { motion } from "framer-motion";
import Container from "@mui/material/Container";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import AOS from "aos";
import "aos/dist/aos.css";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Circle } from "../../Animation/Circle";

import Right from "../../assets/Images/Right.png";
import Left from "../../assets/Images/Left.png";
import Smoke1 from "../../assets/Images/Smoke1.jpg";
import Waters from "../../assets/Images/Waters.png";

const useStyles = makeStyles((theme) => ({
  Root: {
    width: "100%",
    marginTop: "100px",
    height: "700px",
    background: "linear-gradient(100.38deg, #FFFFFF 9.16%, #E9F6FF 98.11%)",
    position: "relative",
    overflowX: "hidden",
  },
  Image_div: {
    backgroundImage: `url(${Smoke1})`,
    width: "100%",
    height: "700px",
    backgroundSize: "100% 100%",
    [theme.breakpoints.down("xs")]: {
      backgroundSize: "contain",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
    },
  },
  MainContainer: {
    display: "flex !important",
    alignItems: "center !important",
    paddingTop: "70px !important",
    marginBottom: "80px !important",
    [theme.breakpoints.down("xs")]: {
      paddingTop: "40px !important",
    },
  },
  Title_text: {
    fontSize: "37px",
    lineHeight: 1.2,
    color: "rgba(0, 62, 106, 1)",
    [theme.breakpoints.down("xs")]: {
      fontSize: "25px",
      textAlign: "left",
    },
  },
  Data_div: {
    fontWeight: 500,
    fontSize: "20px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
    },
  },
  Main_image: {
    width: 500,
    [theme.breakpoints.down("xs")]: {
      width: 360,
      marginTop: "-90px",
      marginLeft: "-20px",
    },
  },
  Drop: {
    background: "green",
    width: "25px",
    height: "25px",
    borderRadius: "50% 0 50% 50%",
    transform: "rotate(-45deg)",
    marginRight: "10px",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      marginTop: "5px",
    },
  },
  Drop2: {
    background: "green",
    width: "25px",
    height: "25px",
    borderRadius: "50% 0 50% 50%",
    transform: "rotate(-45deg)",
    marginRight: "10px",
    marginLeft: "30px",
    [theme.breakpoints.down("xs")]: {
      marginLeft: "30px",
    },
  },
  Drop_Main_div: { display: "flex", flexDirection: "row" },
  Drop_1_div: { display: "flex", alignItems: "center", marginTop: "10px" },
  Drop_div: { display: "flex", alignItems: "center", marginTop: "40px" },
  Drop_2_div: {
    marginLeft: "100px",
    [theme.breakpoints.down("xs")]: {
      marginLeft: "20px",
    },
  },
}));

const SmartWaterSystem = () => {
  const classes = useStyles();
  const theme = useTheme();
  const Mobile = useMediaQuery(theme.breakpoints.down("xs"));

  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <>
      <div className={classes.Root}>
        <div className={classes.Image_div}>
          <div style={{ position: "absolute" }}>
            {Mobile ? (
              <motion.img
                src={Right}
                alt="img"
                variants={Circle}
                initial="initial"
                animate="animate"
                style={{
                  width: "100px",
                }}
              />
            ) : (
              <motion.img
                src={Right}
                alt="img"
                variants={Circle}
                initial="initial"
                animate="animate"
                style={{
                  width: "150px",
                }}
              />
            )}
          </div>
          <div>
            {Mobile ? (
              <motion.img
                src={Left}
                alt="img"
                style={{
                  width: "100px",
                  position: "absolute",
                  right: "0px",
                  bottom: "30px",
                }}
                variants={Circle}
                initial="initial"
                animate="animate"
              />
            ) : (
              <motion.img
                src={Left}
                alt="img"
                style={{
                  width: "150px",
                  position: "absolute",
                  right: "0px",
                  bottom: "30px",
                }}
                variants={Circle}
                initial="initial"
                animate="animate"
              />
            )}
          </div>
          <Container maxWidth="lg">
            <Grid container className={classes.MainContainer}>
              <Grid item lg={6} xs={12}>
                <div data-aos="fade-right">
                  <h1 className={classes.Title_text}>
                    With
                    <span style={{ color: "green" }}>
                      &nbsp;Smart Water System&nbsp;
                    </span>
                    You Can Measure
                  </h1>
                  <div className={classes.Data_div}>
                    <div className={classes.Drop_Main_div}>
                      <div>
                        <div className={classes.Drop_1_div}>
                          <div className={classes.Drop}></div>
                          <div>FLOW</div>
                        </div>
                        <div className={classes.Drop_div}>
                          <div className={classes.Drop}></div>
                          <div>PRESSURE</div>
                        </div>
                        <div className={classes.Drop_div}>
                          <div className={classes.Drop}></div>
                          <div>TEMPERATURE</div>
                        </div>
                        <div className={classes.Drop_div}>
                          <div className={classes.Drop}></div>
                          <div>LEVEL</div>
                        </div>
                      </div>
                      <div className={classes.Drop_2_div}>
                        <div className={classes.Drop_1_div}>
                          <div className={classes.Drop}></div>
                          <div>WATER LEAKS</div>
                        </div>
                        <div className={classes.Drop_div}>
                          <div className={classes.Drop}></div>
                          <div>UN-METERED WATER</div>
                        </div>
                        <div className={classes.Drop_div}>
                          <div className={classes.Drop}></div>
                          <div>INACCURATE BILLING</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid xs={6} item>
                <div style={{ marginTop: "130px" }} data-aos="zoom-in">
                  <img src={Waters} alt="img" className={classes.Main_image} />
                </div>
              </Grid>
            </Grid>
          </Container>
        </div>
      </div>
    </>
  );
};

export default SmartWaterSystem;
