import { Variants } from "framer-motion";

export const Circle: Variants = {
  initial: {},
  animate: {
    y: [30, 0, 30],
    transition: {
      duration: 3.5,
      ease: "linear",
      repeat: Infinity,
    },
  },
};
