import React from "react";
import List from "@mui/material/List";
import Button from "@mui/material/Button";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import HomeIcon from "@mui/icons-material/Home";
import { useHistory } from "react-router-dom";
import ChatIcon from "@mui/icons-material/Chat";
import AssessmentIcon from "@mui/icons-material/Assessment";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";

// import SendIcon from "@mui/icons-material/Send";
// import Collapse from "@mui/material/Collapse";
// import ExpandLess from "@mui/icons-material/ExpandLess";
// import ExpandMore from "@mui/icons-material/ExpandMore";
// import StarBorder from "@mui/icons-material/StarBorder";
// import InboxIcon from "@mui/icons-material/MoveToInbox";
// import SettingsIcon from "@mui/icons-material/Settings";
// import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
// import LaptopIcon from "@mui/icons-material/Laptop";

const MobileMenu = () => {
  const history = useHistory();
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  const GotoAboutUs = () => {
    history.push("/AboutUs");
  };

  const GotoHome = () => {
    history.push("/");
  };

  const GotoMeasurement = () => {
    history.push("/Measurement");
  };

  const GotoDiavaso = () => {
    history.push("/Diavaso");
  };

  const GotoAura = () => {
    history.push("/Aura");
  };

  const GotoCommunication = () => {
    history.push("/Communication");
  };

  return (
    <>
      <div>
        <List>
          <ListItem className="ListItem_div">
            <ListItemButton onClick={GotoHome}>
              <ListItemIcon>
                <HomeIcon />
              </ListItemIcon>
              <ListItemText primary="Home" />
              <ChevronRightIcon />
            </ListItemButton>
          </ListItem>
          <ListItem className="ListItem_div">
            <ListItemButton onClick={GotoMeasurement}>
              <ListItemIcon>
                <AssessmentIcon />
              </ListItemIcon>
              <ListItemText primary="Measurement" />
              <ChevronRightIcon />
            </ListItemButton>
          </ListItem>
          <ListItem className="ListItem_div">
            <ListItemButton onClick={GotoCommunication}>
              <ListItemIcon>
                <AssignmentIndIcon />
              </ListItemIcon>
              <ListItemText primary="Communication" />
              <ChevronRightIcon />
            </ListItemButton>
          </ListItem>
          {/* <ListItem className="ListItem_div">
            <ListItemButton onClick={handleClick}>
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText primary="Software" />
              {open ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
          </ListItem>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemButton
                style={{ marginRight: "16px", marginLeft: "16px" }}
                onClick={GotoAura}
              >
                <ListItemIcon>
                  <PhoneIphoneIcon />
                </ListItemIcon>
                <ListItemText primary="Aura" />
                <ChevronRightIcon />
              </ListItemButton>
              <ListItemButton
                style={{ marginRight: "16px", marginLeft: "16px" }}
                onClick={GotoDiavaso}
              >
                <ListItemIcon>
                  <LaptopIcon />
                </ListItemIcon>
                <ListItemText primary="Diavaso" />
                <ChevronRightIcon />
              </ListItemButton>
            </List>
          </Collapse> */}
          <ListItem className="ListItem_div">
            <ListItemButton onClick={GotoAboutUs}>
              <ListItemIcon>
                <ChatIcon />
              </ListItemIcon>
              <ListItemText primary="About Us" />
              <ChevronRightIcon />
            </ListItemButton>
          </ListItem>
          <ListItem className="ListItem_div">
            <Button
              onClick={GotoAura}
              variant="contained"
              style={{
                color: "white",
                background: "#003256",
                fontSize: "15px",
                fontWeight: 500,
                borderRadius: "10px",
                padding: "5px 20px 4px",
                paddingBottom: "4px",
                cursor: "pointer",
                marginTop: "0",
                marginLeft: "20px",
              }}
            >
              Aura Software
            </Button>
          </ListItem>
        </List>
      </div>
    </>
  );
};

export default MobileMenu;

// onClick={GotoAboutUs}
