import React from "react";
import { useTheme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  Root: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "15px",
    color: "rgba(117, 117, 117, 1)",
    marginTop: "10px",
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      marginTop: "30px",
    },
  },
}));

const CopyRight = () => {
  const theme = useTheme();
  const classes = useStyles();
  return (
    <div>
      <div className={classes.Root}>
        <span>
          Copyright ©2022 | Arss d.o.o., Gosjakova ulica 3, 8000 Novo mesto , T.
          +386 41 357 480, E. info@arss.si
        </span>
      </div>
    </div>
  );
};

export default CopyRight;
