import React from "react";
import { makeStyles } from "@material-ui/core/styles";
// import Hero from "../../../assets/image/About/Hero.png";
import Hero_img from "../../../assets/Images/About/Hero_img.png";

const useStyles = makeStyles((theme) => ({
  Main_Image: {
    width: "100%",
    height: "700px",
    [theme.breakpoints.down("xs")]: {
      objectFit: "cover",
      height: "500px",
    },
  },
}));

const HeroImage = () => {
  const classes = useStyles();

  return (
    <div>
      <div>
        <img src={Hero_img} alt="About Us" className={classes.Main_Image} />
      </div>
    </div>
  );
};

export default HeroImage;
