import React from "react";
import {
  ComposableMap,
  Geographies,
  Geography,
  Graticule,
  Marker,
} from "react-simple-maps";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  Root: {
    cursor: "pointer",
    background: "transparent",
  },
}));

const geoUrl =
  "https://raw.githubusercontent.com/zcreativelabs/react-simple-maps/master/topojson-maps/world-110m.json";

const markers = [
  { markerOffset: -25, name: "Slovenia", coordinates: [14.995463, 46.151241] },
  { markerOffset: 25, name: "Croatia", coordinates: [15.2, 45.1] },
  { markerOffset: 25, name: "Bosnia", coordinates: [17.679076, 43.915886] },
  { markerOffset: 25, name: "Montenegro", coordinates: [19.37439, 42.708678] },
  { markerOffset: 25, name: "Serbia", coordinates: [21.005859, 44.016521] },
  { markerOffset: 25, name: "Romania", coordinates: [24.96676, 45.943161] },
  { markerOffset: 25, name: "Bulgaria", coordinates: [25.48583, 42.733883] },
  { markerOffset: 25, name: "Greece", coordinates: [21.824312, 39.074208] },
  { markerOffset: 25, name: "Greece", coordinates: [21.824312, 39.074208] },
];

const MapChart = () => {
  const classes = useStyles();
  return (
    <ComposableMap
      projection="geoAzimuthalEqualArea"
      projectionConfig={{
        rotate: [-20, -45, 0],
        scale: 1500,
      }}
      className={classes.Root}
    >
      <Graticule stroke="#EAEAEC" />
      <Geographies geography={geoUrl}>
        {({ geographies }) =>
          geographies.map((geo) => (
            <Geography
              key={geo.rsmKey}
              geography={geo}
              fill="#9998A3"
              stroke="#EAEAEC"
            />
          ))
        }
      </Geographies>
      {markers.map(({ name, coordinates, markerOffset }) => (
        <Marker key={name} coordinates={coordinates}>
          <g
            fill="none"
            stroke="#FF5533"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            transform="translate(-12, -24)"
          >
            <circle cx="12" cy="10" r="3" />
            <path d="M12 21.7C17.3 17 20 13 20 10a8 8 0 1 0-16 0c0 3 2.7 6.9 8 11.7z" />
          </g>
          {/* <circle r={10} fill="#F00" stroke="#fff" strokeWidth={2} /> */}
          <text
            textAnchor="middle"
            y={markerOffset}
            style={{ fontFamily: "system-ui", fill: "#fffff" }}
          >
            {name}
          </text>
        </Marker>
      ))}
    </ComposableMap>
  );
};

export default MapChart;
