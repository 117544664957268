import React from "react";
import Header from "../../component/Header";
import CarouselImage from "../../component/CarouselImage";
import WaterMeasurement from "../../component/WaterMeasurement";
import MeasurementText from "../../component/MeasurementText";
import SmartWaterSystem from "../../component/SmartWaterSystem";
import AddDiv from "../../component/AddDiv";
import ScrollCards from "../../component/ScrollCards";
import Footer from "../../component/Footer";
import CopyRight from "../../component/CopyRight";

const LandingPage = () => {
  window.scrollTo(0, 0);

  return (
    <div>
      <Header />
      <CarouselImage />
      <MeasurementText Title="Advanced Measurement Tools" />
      <WaterMeasurement />
      <SmartWaterSystem />
      <AddDiv />
      <ScrollCards />
      <div style={{ marginTop: "100px" }}>
        <Footer />
      </div>
      <CopyRight />
    </div>
  );
};

export default LandingPage;
