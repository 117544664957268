import React from "react";
import Logo from "../../assets/Logo/Logo.png";
import Container from "@mui/material/Container";
import RoomIcon from "@mui/icons-material/Room";
import CallIcon from "@mui/icons-material/Call";
import WebIcon from "@mui/icons-material/Language";
import MailIcon from "@mui/icons-material/Mail";

import { makeStyles } from "@material-ui/core/styles";
import Grid from "@mui/material/Grid";
import { useTheme } from "@material-ui/core/styles";

import work from "../../assets/Images/work.png";
import Bubble1 from "../../assets/Images/Bubble1.png";
import Bubble2 from "../../assets/Images/Bubble2.png";

const useStyles = makeStyles((theme) => ({
  Root: {
    width: "100%",
    height: "400px",
    background: "#002540",
    position: "relative",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      height: "100%",
    },
  },
  Container_div: {
    [theme.breakpoints.down("xs")]: {
      paddingTop: "30px",
    },
  },
  Main_container: {
    paddingTop: "70px",
    display: "flex",
    color: "white",
    justifyContent: "space-around",
    fontSize: "14px",
  },
  Right_image: {
    position: "absolute",
    top: "-60px",
  },
  Left_image: {
    position: "absolute",
    right: "0px",
    bottom: "0",
  },
  Logo_div: {
    marginBottom: "30px",
    width: "100%",
  },
  Para_div: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    paddingRight: "120px",
    [theme.breakpoints.down("xs")]: {
      marginTop: "-20px",
      paddingRight: "10px",
    },
  },
  Main_Partner_div: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  Partner_div: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      marginTop: "20px",
    },
  },
  Partner_image: {
    width: "150px",
    height: "70px",
    [theme.breakpoints.down("xs")]: {
      marginTop: "-10px",
    },
  },
  Partner_title: {
    width: "max-content",
  },
  Location_div: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      marginTop: "20px",
      paddingRight: "0px",
      paddingLeft: "0px",
      marginLeft: "-2px",
    },
  },
  Location_sub_div: {
    display: "flex",
    alignItems: "flex-start",
  },
  Location_icon_div: {
    marginRight: "15px",
  },
  Location_icon: {
    width: "30px !important",
    height: "30px !important",
  },
}));

const Footer = () => {
  const classes = useStyles();
  const theme = useTheme();
  return (
    <>
      <div className={classes.Root}>
        <div>
          <img src={Bubble1} alt="bubble" className={classes.Right_image} />
        </div>
        <div>
          <img src={Bubble2} alt="bubble" className={classes.Left_image} />
        </div>
        <Container maxWidth="lg" className={classes.Container_div}>
          <div className={classes.Main_container}>
            <Grid container>
              <Grid item lg={3} xs={12}>
                <div className={classes.Para_div}>
                  <div className={classes.Logo_div}>
                    <img src={Logo} alt="logo" style={{ width: "180px" }} />
                  </div>
                  {
                    <div style={{ width: "220px" }}>
                      <p>
                        OUR MISSION and TARGET: Inovative and flexible business
                        models. Long-term project sustainability. Reduction of
                        environmental footprint. Satisfied customer and solving
                        waters.
                      </p>
                    </div>
                  }
                </div>
              </Grid>
              <Grid item lg={3} xs={12}>
                <div className={classes.Main_Partner_div}>
                  <div className={classes.Partner_div}>
                    <p className={classes.Partner_title}>
                      We Work In Accordance With
                    </p>
                    <div>
                      <img
                        src={work}
                        alt="img"
                        className={classes.Partner_image}
                      />
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item lg={3} xs={12}>
                <div className={classes.Location_div}>
                  <div className={classes.Location_sub_div}>
                    <div className={classes.Location_icon_div}>
                      <RoomIcon className={classes.Location_icon} />
                    </div>
                    <div>
                      ARSS d.o.o.
                      <br />
                      <p>Gosjakova ulica 3, 8000 Novo mesto Sl- Slovenia</p>
                    </div>
                  </div>
                  <div className={classes.Location_sub_div}>
                    <div className={classes.Location_icon_div}>
                      <CallIcon className={classes.Location_icon} />
                    </div>
                    <div>+386 41 357 480</div>
                  </div>
                  <div className={classes.Location_sub_div}>
                    <div className={classes.Location_icon_div}>
                      <MailIcon className={classes.Location_icon} />
                    </div>
                    <div>info@arss.si</div>
                  </div>
                </div>
              </Grid>
              <Grid item lg={3} xs={12}>
                <div className={classes.Location_div}>
                  <div className={classes.Location_sub_div}>
                    <div className={classes.Location_icon_div}>
                      <RoomIcon className={classes.Location_icon} />
                    </div>
                    <div>
                      ARSS TECHNOLOGY DOO
                      <p>
                        Milentija Popovića 5, sprat I 11070
                        <br />
                        Beograd - Novi Beograd
                        <br />
                      </p>
                    </div>
                  </div>
                  <div className={classes.Location_sub_div}>
                    <div className={classes.Location_icon_div}>
                      <WebIcon className={classes.Location_icon} />
                    </div>
                    <div>
                      <a href="#" target="_blank" style={{ color: "white" }}>
                        www.arss-solutions.com
                      </a>
                    </div>
                    <br />
                  </div>
                  <div className={classes.Location_sub_div}>
                    <div className={classes.Location_icon_div}>
                      <CallIcon className={classes.Location_icon} />
                    </div>
                    <div>+381 69 184 9288 </div>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Footer;
