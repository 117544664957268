import React from "react";
import { motion } from "framer-motion";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import { makeStyles } from "@material-ui/core/styles";
import Header from "../../component/Header";
import Footer from "../../component/Footer";
import CopyRight from "../../component/CopyRight";
import MapChart from "./MapChart";
import { Circle } from "../../Animation/Circle";
import { TitleText, FadeIn } from "../../Animation/TitleText";
import HeroImage from "./HeroImage/HeroImage";

import Right from "../../assets/Images/Right.png";
import Main_img from "../../assets/Images/About/Main_img.png";
import Globe from "../Globe";

const useStyles = makeStyles((theme) => ({
  Root: {
    width: "100%",
    height: "100%",
    paddingTop: "50px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    flexDirection: "column",
    // background: "linear-gradient(180deg, #FFFFFF 50.04%, #D7EEFF 103.28%)",
    background: "white",
  },
  Circle_div: {
    position: "absolute",
    left: 0,
    top: 0,
    zIndex: 1,
  },
  Text_div: {
    marginTop: "50px",
    [theme.breakpoints.down("xs")]: {
      marginTop: "0px",
    },
  },
  Image_div: {
    width: "280px",
    [theme.breakpoints.down("xs")]: {
      width: "300px",
      marginTop: "50px",
    },
  },
  Title: {
    fontSize: "35px",
    lineHeight: 1.2,
    fontWeight: 600,
    color: "rgba(0, 62, 106, 1)",
    [theme.breakpoints.down("xs")]: {
      fontSize: "25px",
    },
  },
  Para_div: {
    fontSize: "15px",
    fontWeight: 400,
    paddingRight: "130px",
    marginTop: "50px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px",
      paddingRight: "0px",
      marginTop: "20px",
    },
  },
  Pdf_div: {
    width: "240px",
    display: "flex",
    alignItems: "center",
    marginTop: "50px",
    cursor: "pointer",
    border: "2px solid rgba(0, 62, 106, 1)",
    borderRadius: "5px",
    padding: "10px",
    fontSize: "19px",
    fontWeight: 500,
    [theme.breakpoints.down("xs")]: {
      marginTop: "20px",
      width: "230px",
    },
  },
  Drop_icon: {
    background: "green",
    width: "25px",
    height: "25px",
    borderRadius: "50% 0 50% 50%",
    transform: "rotate(-45deg)",
    marginRight: "10px",
  },
  Hero_text_div: {
    position: "absolute",
    zIndex: 1,
    width: "100%",
    height: "80%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    textAlign: "center",
  },
  Hero_Title: {
    fontSize: "65px",
    fontWeight: 600,
    lineHeight: 1,
    color: "white",
    [theme.breakpoints.down("xs")]: {
      fontSize: "35px",
    },
  },
  Map_div: {
    margin: "0 auto",
    width: "100%",
    paddingLeft: "200px",
    paddingRight: "200px",
    [theme.breakpoints.down("xs")]: {
      paddingLeft: "0px",
      paddingRight: "40px",
    },
  },
}));

const AboutUs = () => {
  const classes = useStyles();
  window.scrollTo(0, 0);

  return (
    <div>
      <Header />
      <motion.div
        variants={TitleText}
        initial="initial"
        animate="animate"
        className={classes.Hero_text_div}
      >
        <motion.span variants={FadeIn("down")} className={classes.Hero_Title}>
          About ARSS
        </motion.span>
      </motion.div>
      <HeroImage />
      <div className={classes.Root}>
        <div className={classes.Circle_div}>
          <motion.img
            variants={Circle}
            initial="initial"
            animate="animate"
            src={Right}
            alt="img"
            style={{ width: "130px" }}
          />
        </div>
        <Container maxWidth="lg">
          <Grid container>
            <Grid item lg={6} md={6} xs={12}>
              <div className={classes.Text_div}>
                <div>
                  <h1 className={classes.Title}>
                    <span style={{ color: "#00A859" }}>Who is Arss?&nbsp;</span>
                  </h1>
                </div>
                <div className={classes.Para_div}>
                  <p>
                    Arss company is backed by knowledge and many years of
                    experience in hardware and especially software solutions in
                    the digital world of water reading and water flow
                    measurement. We are a team of young, innovative, strong and
                    experienced people. Working on daily improvement of our
                    system and software component. A high-performance company
                    culture and a clear plan for short- and long-term growth
                    globally, we are building on the strength of our history to
                    deliver a higher level of customer service, innovation and
                    value — today and well into the future.
                  </p>
                </div>
              </div>
            </Grid>
            <Grid item lg={6} md={6} xs={12}>
              <div>
                <img src={Main_img} alt="img" className={classes.Image_div} />
              </div>
            </Grid>
          </Grid>
          {/* <MapChart /> */}
          {/* <Globe /> */}
          <img src='/maparss.jpg' style={{ maxWidth: '100%' }} />

        </Container>
      </div>
      <div>
        <Footer />
        <CopyRight />
      </div>
    </div>
  );
};

export default AboutUs;
