import React from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import useStyles from "./style";
import AOS from "aos";
import "aos/dist/aos.css";

import Circles from "../../assets/Images/Circles.png";

const WaterMeasurement = () => {
  const classes = useStyles();

  AOS.init({
    duration: 2000,
  });

  return (
    <>
      <div className={classes.Root}>
        <div className={classes.Image_div}>
          <Container maxWidth="lg">
            <Grid container className={classes.MainContainer}>
              <Grid xs={6} item>
                <div data-aos="zoom-in">
                  <img
                    src={Circles}
                    alt="img1"
                    className={classes.Main_image}
                  />
                </div>
              </Grid>
              <Grid item lg={6} xs={12}>
                <div className={classes.Text_div} data-aos="fade-left">
                  <h1 className={classes.Title_text}>
                    Perfect solutions for better
                    <span style={{ color: "green" }}>&nbsp;control</span> over
                    water
                  </h1>
                  <div>
                    <div className={classes.Icon_with_text}>
                      <div className={classes.Drop}></div>
                      <span>Water Measurement</span>
                    </div>
                    <div className={classes.Para_div}>
                      <p>
                        View our full range of advanced measurement products.
                      </p>
                    </div>
                  </div>
                  <div>
                    <div className={classes.Icon_with_text}>
                      <div className={classes.Drop}></div>
                      <span>Advanced Communication</span>
                    </div>
                    <div className={classes.Para_div}>
                      <p>
                        We offer wireless or wired innovative communication
                        solutions.
                      </p>
                    </div>
                  </div>
                  <div>
                    <div className={classes.Icon_with_text}>
                      <div className={classes.Drop}></div>
                      <span>Software solution</span>
                    </div>
                    <div className={classes.Para_div}>
                      <p>
                        AURA, a software solution for supporting digitalisation
                        of the water utility processes.
                      </p>
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Container>
        </div>
      </div>
    </>
  );
};
export default WaterMeasurement;
