import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import LandingPage from "./pages/LandingPage";
import AboutUs from "./pages/AboutUs";
import Aura from "./pages/Aura";
import Communication from "./pages/Communication";
import Measurement from "./pages/Measurement/";
import Globe from "./pages/Globe";

function App() {
  return (
    <div>
      <Router>
        <Switch>
          <Route exact path="/" component={LandingPage} />
          <Route exact path="/AboutUs" component={AboutUs} />
          <Route exact path="/globe" component={Globe} />
          <Route exact path="/Aura" component={Aura} />
          <Route exact path="/Communication" component={Communication} />
          <Route exact path="/Measurement" component={Measurement} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
